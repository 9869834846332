// Pages
import Abogados from "./pages/abogados";
import Bancos from "./pages/bancos";
import CargueCartera from "./pages/cargueCartera";
import CargueProyecto from "./pages/cargueProyecto";
import Clientes from "./pages/clientes";
import Constructores from "./pages/constructores";
import Dashboard from "./pages/dashboard";
import Fases from "./pages/fases";
import Fiducias from "./pages/fiducias";
import Listas from "./pages/listas";
import Login from "./pages/login";
import Logout from "./pages/login/logout";
import Notarias from "./pages/notarias";
import Peritos from "./pages/peritos";
import Proyectos from "./pages/proyectos";
import Reportes from "./pages/reportes";
import Seguimiento from "./pages/seguimiento";
import Usuarios from "./pages/usuarios";
import Escrituracion from "./pages/escrituracion";
import Documentos from "./pages/documentos";

const routes = [

  {
    route: "/abogados",
    component: <Abogados />,
    role: ['Admin']
  },
  {
    route: "/bancos",
    component: <Bancos />,
    role: ['Admin']
  },
  {
    route: "/cargue-cartera",
    component: <CargueCartera />,
    role: ['Admin']
  },
  {
    route: "/cargue-proyecto",
    component: <CargueProyecto />,
    role: ['Admin']
  },
  {
    route: "/clientes",
    component: <Clientes />,
    role: ['Admin', 'Tramitador']
  },
  {
    route: "/constructores",
    component: <Constructores />,
    role: ['Admin']
  },
  {
    route: "/dashboard",
    component: <Dashboard />,
    role: ['Admin', "Tramitador"]
  },
  {
    route: "/fases",
    component: <Fases />,
    role: ['Admin']
  },
  {
    route: "/fiducias",
    component: <Fiducias />,
    role: ['Admin']
  },
  {
    route: "/listas",
    component: <Listas />,
    role: ['Admin']
  },
  {
    route: "/inmuebles",
    component: <Escrituracion />,
    role: ['Admin', 'Tramitador']
  },
  {
    route: "/login",
    role: "auth",
    component: <Login />,
  },
  {
    route: "/logout",
    component: <Logout />,
    role: ['Admin']
  },
  {
    route: "/notarias",
    component: <Notarias />,
    role: ['Admin']
  },
  {
    route: "/peritos",
    component: <Peritos />,
    role: ['Admin']
  },
  {
    route: "/proyectos",
    component: <Proyectos />,
    role: ['Admin', 'Tramitador']
  },
  {
    route: "/reportes",
    component: <Reportes />,
    role: ['Admin']
  },
  {
    route: "/seguimiento",
    component: <Seguimiento />,
    role: ['Admin', 'Tramitador']
  },
  {
    route: "/usuarios",
    component: <Usuarios />,
    role: ['Admin']
  },
  {
    route: "/documentos",
    component: <Documentos />,
    role: ['Admin']
  },
];

export default routes;
