import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
  Skeleton,
} from "@mui/material";
import { LabelImportant, Menu as MenuIcon } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { useRutas } from "../components/fetchRutas";

const drawerWidth = 240;

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

function SideBar({ open, handleDrawerToggle }) {
  const { paths } = useRutas();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  const isLoading = !paths || paths.length === 0; // Asume que paths es un array vacío o undefined mientras se cargan

  const menuItems = paths.map((path) => ({
    text: capitalizeFirstLetter(path.replace("/", "") || "Inicio"),
    link: path,
  }));

  const drawerContent = (
    <>
      <Toolbar>
        <Typography variant="h6" noWrap component="div">
          Menú
        </Typography>
      </Toolbar>
      <List sx={{ minHeight: "100vh" }}>
        {isLoading ? (
          Array.from(new Array(11)).map((_, index) => (
            <ListItem key={index}>
              <Skeleton variant="text" width="100%" height={40} />
            </ListItem>
          ))
        ) : (
          menuItems.map(({ text, link }) => (
            <ListItem
              key={text}
              component={NavLink}
              to={link}
              sx={{
                paddingLeft: 2.5,
                paddingRight: 2.5,
                "&.active": {
                  backgroundColor: theme.palette.action.selected,
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LabelImportant />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))
        )}
      </List>
    </>
  );

  return (
    <>
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={open}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            position: "relative",
            height: "100%",
            overflowX: "hidden",
            transition: "width 0.3s",
            borderRight: "1px solid #ccc",
          },
        }}
      >
        {drawerContent}
      </Drawer>
      {isMobile && (
        <IconButton
          onClick={handleDrawerToggle}
          sx={{
            position: "absolute",
            left: 0,
            top: 22,
            zIndex: theme.zIndex.appBar + 1,
            color: "#FFF",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <MenuIcon />
        </IconButton>
      )}
    </>
  );
}

export default React.memo(SideBar);
