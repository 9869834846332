import React from 'react';
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Stack, Box } from '@mui/material';

const ConstructorContactForm = ({
    control,
    constructors,
    contacts,
    errors,
    handleConstructorChange,
    selectedContact,
    setSelectedContact,
    constructorName,
    constructorLabel,
    contactName,
    contactLabel,
    defaultValue,
}) => {
    return (
        <>
            <Controller
                name={constructorName}
                control={control}
                defaultValue={defaultValue || ""}
                render={({ field }) => (
                    <Autocomplete
                        {...field}
                        options={constructors}
                        getOptionLabel={(option) => option.name || option.bank_name || ""}
                        isOptionEqualToValue={(option, value) => option.name === value?.name || option.bank_name === value?.bank_name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={constructorLabel}
                                variant="outlined"
                                error={!!errors[constructorName]}
                                helperText={errors[constructorName] ? errors[constructorName].message : ""}
                            />
                        )}
                        onChange={(_, selectedConstructor) => {
                            field.onChange(selectedConstructor);
                            handleConstructorChange(selectedConstructor);
                        }}
                    />
                )}
            />

            {contacts.length > 0 && (
                <Controller
                    name={contactName}
                    control={control}
                    render={({ field }) => (
                        <Autocomplete
                            {...field}
                            options={contacts}
                            getOptionLabel={(option) => option.contact_name || ""}
                            isOptionEqualToValue={(option, value) => option.contact_name === value?.contact_name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={contactLabel}
                                    variant="outlined"
                                    fullWidth
                                />
                            )}
                            onChange={(_, data) => {
                                field.onChange(data);
                                setSelectedContact(data);
                            }}
                        />
                    )}
                />
            )}

            {selectedContact && (
                <Stack direction={{ md: "row", xs: "column" }} spacing={2} sx={{ margin: 0 }}>
                    <Box sx={{ flex: 1 }}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            value={selectedContact.email}
                            disabled
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <TextField
                            label="Teléfono"
                            variant="outlined"
                            value={selectedContact.phone}
                            disabled
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <TextField
                            label="Cargo"
                            variant="outlined"
                            value={selectedContact.position}
                            disabled
                            fullWidth
                        />
                    </Box>
                </Stack>
            )}
        </>
    );
};

export default ConstructorContactForm;
