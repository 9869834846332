import React, { useState, useEffect, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
	Autocomplete,
	TextField,
	Button,
	Typography,
	Box,
	CircularProgress,
	Card,
	CardActions,
	CardContent,
	FormControl,
	Select,
	MenuItem,
	InputLabel,
	FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import StraightenIcon from "@mui/icons-material/Straighten";
import useSnack from "../../hooks/useSnack";
import useApi from "../../hooks/useApi";
import MenuView from "../../components/MenuView";
import SnackAlert from "../../components/SnackAlert";

const schema = yup.object().shape({
	file: yup
		.mixed()
		.test(
			"required",
			"Por favor selecciona un archivo",
			(value) => value && value.length > 0,
		)
		.test(
			"fileType",
			"El archivo debe ser un Excel (.xlsx, .xls o .xlsm)",
			(value) =>
				value &&
				[
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					"application/vnd.ms-excel",
					"application/vnd.ms-excel.sheet.macroEnabled.12",
				].includes(value[0]?.type),
		),
	category: yup.string().required("Por favor selecciona una categoría"),
	subCategory: yup.string().when("category", {
		is: "cartera",
		then: () =>
			yup.string().required("Por favor selecciona una subcategoría"),
		otherwise: () => yup.string().notRequired(),
	}),
	project: yup.object().shape({
		project_name: yup.string().required("Project is required"),
		id: yup.number().required("Project id is required"),
	}),
});

const Input = styled("input")({
	display: "none",
});

export default function Documentos() {
	const { showSnack, msgSB, openSB, typeSB } = useSnack();
	const { loadApi } = useApi();
	const [fileInfo, setFileInfo] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showSelectSubCategory, setShowSelectSubCategory] = useState(false);
	const [projects, setProjects] = useState([]);
	const refHiddenInput = useRef(null);

	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		setValue,
		clearErrors,
		watch,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValues: {
			project: null,
			file: null,
			category: "",
			subCategory: "",
		},
		mode: "onChange",
	});

	const resetForm = () => {
		setFileInfo(null);
		setValue("project", null);
		setValue("file", null);
		setValue("category", "");
		setValue("subCategory", "");
		clearErrors();
		if (refHiddenInput.current) {
			refHiddenInput.current.value = "";
		}
	};

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setFileInfo({
				name: file.name,
				size: (file.size / 1024).toFixed(2) + " KB",
				type: file.type,
			});
			// Marcar el formulario como "sucio" y actualizar el valor
			setValue("file", event.target.files, {
				shouldDirty: true,
				shouldValidate: true
			});
		}
	};

	const handleRemoveFile = () => {
		setFileInfo(null);
		setValue("file", null, {
			shouldDirty: true,
			shouldValidate: true
		});
		clearErrors("file");
		if (refHiddenInput.current) {
			refHiddenInput.current.value = "";
		}
	};

	const onSubmit = async (data) => {
		const formData = {
			project: data.project,
			document: data.file[0],
			category: data.category,
		};

		let endpoint = '';

		// Determine the endpoint based on category and subcategory
		if (data.category === 'cartera') {
			switch (data.subCategory) {
				case 'formato-1-sica':
					endpoint = `upload/document/sica/${formData.project.id}`;
					break;
				case 'informe-cartera-sap':
					endpoint = `upload/document/sap/${formData.project.id}`;
					break;
				case 'formato-sharu':
					endpoint = `upload/document/sharu/${formData.project.id}`;
					break;
				default:
					showSnack('Subcategoría no válida', 'error');
					return;
			}
		} else if (data.category === 'prorrata') {
			endpoint = `upload/document/prorrata/${formData.project.id}`;
		} else if (data.category === 'formato-principal') {
			endpoint = `upload/document/${formData.project.id}`;
		} else {
			showSnack('Categoría no válida', 'error');
			return;
		}

		setLoading(true);
		loadApi(endpoint, true, "post", formData, true)
			.then((response) => {
				showSnack(response.data.message, "success");
				resetForm();
			})
			.catch((error) => {
				setLoading(false);
				showSnack(error.message, "error");
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		const subscription = watch((value, { name }) => {
			if (name === "category") {
				setShowSelectSubCategory(value.category === "cartera");
				if (value.category !== "cartera") {
					setValue("subCategory", "", { shouldValidate: true });
				}
			}
		});
		return () => subscription.unsubscribe();
	}, [watch, setValue]);

	useEffect(() => {
		loadApi("project/all_projects", true, "get")
			.then((response) => {
				setProjects(response.data || []);
			})
			.catch((error) => {
				showSnack(error.message);
			});
	}, []);

	return (
		<MenuView>
			<Box
				sx={{
					height: "100%",
					backgroundColor: "#FFFF",
					borderRadius: ".5em",
					boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
					overflow: "auto",
				}}
			>
				<Box sx={{ padding: "1em" }}>
					<Typography sx={{ mb: 1 }} gutterBottom>
						Cargue de Documentos
					</Typography>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
								gap: 2,
								width: "100%",
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: { xs: "column", md: "row" },
									justifyContent: "center",
									alignItems: "center",
									gap: 2,
									width: "100%",
								}}
							>
								<Controller
									name="project"
									control={control}
									defaultValue={null}
									render={({ field }) => (
										<FormControl
											fullWidth
											error={!!errors.project}
										>
											<Autocomplete
												{...field}
												disablePortal
												id="combo-box-projects"
												options={projects}
												getOptionLabel={(option) =>
													option.project_name
												}
												isOptionEqualToValue={(
													option,
													value,
												) => option.id === value?.id}
												onChange={(_, value) => {
													field.onChange(value);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Proyecto"
														variant="outlined"
													/>
												)}
											/>
										</FormControl>
									)}
								/>

								<Controller
									name="file"
									control={control}
									defaultValue=""
									render={({
										field: { onChange, value, ...field },
									}) => (
										<>
											<Input
												accept=".xlsx,.xls,.xlsm"
												id="contained-button-file"
												type="file"
												ref={refHiddenInput}
												onChange={(e) => {
													handleFileChange(e);
												}}
												sx={{ width: "100%" }}
											/>
											<Button
												variant="contained"
												startIcon={<CloudUploadIcon />}
												disabled={!!fileInfo}
												onClick={() => {
													refHiddenInput.current.click();
												}}
												sx={{ width: "100%" }}
											>
												Seleccionar archivo Excel
												(.xlsx, .xls, .xlsm)
											</Button>
										</>
									)}
								/>
							</Box>
							{errors.file && (
								<Typography color="error" variant="body2">
									{errors.file.message}
								</Typography>
							)}

							{fileInfo && (
								<Card
									variant="outlined"
									sx={{ width: "100%" }}
								>
									<CardContent>
										<Typography
											variant="h6"
											sx={{ textAlign: "center" }}
										>
											Información del archivo
										</Typography>
										<Box
											sx={{
												display: "flex",
												flexDirection: {
													xs: "column",
													md: "row",
												},
												justifyContent: {
													xs: "start",
													md: "center",
												},
												alignItems: {
													xs: "start",
													md: "center",
												},
												gap: 3,
												my: 2,
											}}
										>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "center",
													alignItems: "center",
													gap: 1,
												}}
											>
												<Box>
													<DescriptionIcon size="large" />
												</Box>
												<Box>
													<Typography variant="body1">
														Nombre
													</Typography>
													<Typography
														variant="body1"
														color="text.secondary"
													>
														{fileInfo.name}
													</Typography>
												</Box>
											</Box>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "center",
													alignItems: "center",
													gap: 1,
												}}
											>
												<Box>
													<StraightenIcon size="large" />
												</Box>
												<Box>
													<Typography variant="body1">
														Tamaño
													</Typography>
													<Typography
														variant="body1"
														color="text.secondary"
													>
														{fileInfo.size}
													</Typography>
												</Box>
											</Box>
										</Box>
									</CardContent>
									<CardActions>
										<Button
											variant="outlined"
											color="error"
											startIcon={<DeleteIcon />}
											onClick={handleRemoveFile}
											fullWidth
										>
											Quitar archivo
										</Button>
									</CardActions>
								</Card>
							)}

							<Controller
								name="category"
								control={control}
								render={({ field }) => (
									<FormControl
										fullWidth
										error={!!errors.category}
									>
										<InputLabel id="category-label">
											Categoría
										</InputLabel>
										<Select
											labelId="category-label"
											label="Categoría"
											required
											{...field}
										>
											<MenuItem value="formato-principal">
												Formato principal
											</MenuItem>
											<MenuItem value="cartera">
												Cartera
											</MenuItem>
											<MenuItem value="prorrata">
												Prorrata
											</MenuItem>
										</Select>
										<FormHelperText>
											{errors.category?.message}
										</FormHelperText>
									</FormControl>
								)}
							/>

							{showSelectSubCategory && (
								<Controller
									name="subCategory"
									control={control}
									render={({ field }) => (
										<FormControl
											fullWidth
											error={!!errors.subCategory}
										>
											<InputLabel id="sub-category-label">
												Subcategoría de Cartera
											</InputLabel>
											<Select
												labelId="sub-category-label"
												label="Subcategoría de Cartera"
												required
												{...field}
											>
												<MenuItem value="formato-1-sica">
													Formato 1 - SICA
												</MenuItem>
												<MenuItem value="informe-cartera-sap">
													Informe de cartera - SAP
												</MenuItem>
												<MenuItem value="formato-sharu">
													Formato SHARU
												</MenuItem>
											</Select>
											<FormHelperText>
												{errors.subCategory?.message}
											</FormHelperText>
										</FormControl>
									)}
								/>
							)}

							<Button
								type="submit"
								variant="contained"
								color="primary"
								disabled={loading || !fileInfo || !isValid}
								fullWidth
							>
								{loading ? (
									<CircularProgress size={24} />
								) : (
									"Enviar"
								)}
							</Button>
						</Box>
					</form>
				</Box>
			</Box>
			<SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
		</MenuView>
	);
}
