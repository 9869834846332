import React, { useState } from "react";
import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ModalCrear from "./modalCrear";
import Plantilla from './crear'
export default function Crear({ showSnack, handleEscriturasUpdate }) {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button
        variant="contained"
        fullWidth
        onClick={() => handleOpenModal('insert')}
        startIcon={<AddIcon />}
      >
        Crear nueva
      </Button>
      <ModalCrear
       open={openModal}
       handleClose={handleCloseModal}
       showSnack={showSnack}
       handleEscriturasUpdate={handleEscriturasUpdate} 
       Ruta={Plantilla}
       />
    </>
  );
}
