import { useState, useEffect } from "react";
import useApi from "../hooks/useApi";

export const useRutas = () => {
  const { loadApi } = useApi();
  const [paths, setPaths] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await loadApi("modules/", true, "get");
        if (response.data) {
          setPaths(response.data.paths || []);
        }
      } catch (error) {
        setPaths([]);
      }
    };
    fetchData();
  }, []);

  return {paths} ;
};

