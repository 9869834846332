import React, { useState } from "react";
import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ModalCrear from "./modalHistorial"

export default function Crear({ showSnack, handleHistorialUpdate, rowData }) {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button
        variant="contained"
        fullWidth
        onClick={() => handleOpenModal('insert')}
        startIcon={<AddIcon />}
      >
        Crear nueva
      </Button>
      <ModalCrear
       open={openModal}
       handleClose={handleCloseModal}
       showSnack={showSnack}
       rowData={rowData}
       handleHistorialUpdate={handleHistorialUpdate} 
       />
    </>
  );
}
