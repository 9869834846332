import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import MenuView from "../../components/MenuView";
import {
  Alert,
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  FormHelperText,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  Autocomplete,
  Modal,
  IconButton,
  InputAdornment,
  Paper,
  Fade,
  Stepper,
  Step,
  StepLabel,
  StepButton
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import DoneIcon from "@mui/icons-material/Done";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";
import BackButton from "../../components/buttonBack";
import ModalDocument from "./components/modalDocumentos"

const projectSchema = yup.object().shape({
  project_name: yup
    .string()
    .required("Requerido")
    .matches(/^[a-zA-ZÀ-ÿ\s]+$/, "Debe ser solo letras"),
  address: yup
    .string()
    .required("Requerido")
    .matches(/^[a-zA-ZÀ-ÿ0-9\s#-]+$/, "Dirección invalida"),
  city: yup
    .string()
    .required("Requerido")
    .matches(/^[a-zA-ZÀ-ÿ\s]+$/, "Debe ser solo letras y puede contener caracteres especiales"),
  name_contact: yup
    .string()
    .required("Requerido")
    .matches(/^[a-zA-ZÀ-ÿ\s]+$/, "Debe ser solo letras"),
  email: yup.string().required("Requerido").email("Email inválido"),
  notarie_id: yup.string().required("Requerido"),
  bank_id: yup.string().required("Requerido"),
  trust_id: yup.string().required("Requerido"),
  constructor_id: yup.string().required("Requerido"),
});

const phaseSchema = yup.object().shape({
  phases_id: yup.string().required("Requerido"),
  project_id: yup.string().required("Requerido"),
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #FFFF",
  borderRadius: ".5em",
  boxShadow: 24,
  p: 4,
};



const stepperModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxHeight: "50%",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "2px solid #FFFF",
  borderRadius: ".5em",
  boxShadow: 24,
  p: 4,
  mt: 4,
};

const tableStyle = {
  header: { style: { backgroundColor: "#FFFF", fontSize: "16px", fontWeight: "bold" } },
  rows: { style: { fontSize: "14px", fontWeight: "400" } },
  columns: {
    style: {
      width: "150px",
      maxWidth: "150px",
      minWidth: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
};

const textFieldStyle = (enabled) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderColor: enabled ? "black" : "default" },
  },
});

export default function Projects() {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState([]);
  const [notaries, setNotaries] = useState([]);
  const [banks, setBanks] = useState([]);
  const [trusts, setTrusts] = useState([]);
  const [constructors, setConstructors] = useState([]);
  const [editableFieldsDisabled, setEditableFieldsDisabled] = useState(true);
  const [creating, setCreating] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openEditPhase, setOpenEditPhase] = useState(false);
  const [openCreatePhase, setOpenCreatePhase] = useState(false);
  const [openStepperModal, setOpenStepperModal] = useState(false);
  const [phases, setPhases] = useState([]);
  const [phasesXProject, setPhasesXProject] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [search, setSearch] = useState("");
  const [currentEditPhase, setCurrentEditPhase] = useState({
    id: "",
    phases_id: "",
    project_id: "",
  });
  const [newPhase, setNewPhase] = useState({
    phases_id: "",
    project_id: "",
  });
  const { control, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(projectSchema),
  });
  const { control: phaseControl, handleSubmit: handlePhaseSubmit, setValue: setPhaseValue, formState: { errors: phaseErrors } } = useForm({
    resolver: yupResolver(phaseSchema),
  });

  const { loadApi } = useApi();
  const { showSnack, msgSB, openSB, typeSB } = useSnack();

  useEffect(() => {
    getData();
    getNotaries();
    getBanks();
    getTrusts();
    getConstructors();
    getPhases();
  }, []);

  const getData = async () => {
    setLoading(true);
    loadApi("project/all_projects", true, "get")
      .then((response) => setData(response.data || []))
      .catch((error) => {
        setData([]);
        showSnack(error.message, "error");
      })
      .finally(() => setLoading(false));
  };

  const getNotaries = () => {
    setLoading(true);
    loadApi("notaries/all_notaries", true, "get")
      .then((response) => setNotaries(response.data || []))
      .catch((error) => showSnack(error.message, "error"))
      .finally(() => setLoading(false));
  };

  const getBanks = () => {
    setLoading(true);
    loadApi("banks/all_banks", true, "get")
      .then((response) => setBanks(response.data || []))
      .catch((error) => showSnack(error.message, "error"))
      .finally(() => setLoading(false));
  };

  const getTrusts = () => {
    setLoading(true);
    loadApi("trusts/all_trusts", true, "get")
      .then((response) => setTrusts(response.data || []))
      .catch((error) => showSnack(error.message, "error"))
      .finally(() => setLoading(false));
  };
  const getConstructors = () => {
    setLoading(true);
    loadApi("constructor/all_constructors", true, "get")
      .then((response) => setConstructors(response.data || []))
      .catch((error) => showSnack(error.message, "error"))
      .finally(() => setLoading(false));
  };

  const getPhases = () => {
    setLoading(true);
    loadApi("phase/all_phases", true, "get")
      .then((response) => setPhases(response.data || []))
      .catch((error) => showSnack(error.message, "error"))
      .finally(() => setLoading(false));
  };

  const getPhasesByProject = async (projectId) => {
    setLoading(true);
    loadApi(`phases_project/all_phases_project/${projectId}`, true, "get")
      .then((response) => setPhasesXProject(response.data || []))
      .catch((error) => {
        setPhasesXProject([]);
        showSnack(error.message, "error");
      })
      .finally(() => setLoading(false));
  };

  const handleActivateCreate = () => {
    setEditableFieldsDisabled(false);
    setCreating(true);
  };

  const handleCreate = async (data) => {
    setLoading(true);
    loadApi("project", true, "post", data)
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
        setCreating(false);
        clearFields();
      })
      .catch((error) => showSnack(error.message, "error"))
      .finally(() => setLoading(false));
  };

  const handleSaveEdit = (data) => {
    setLoading(true);
    loadApi(`project/update_project/${selected.id}`, true, "put", data)
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
      })
      .catch((error) => showSnack(error.message, "error"))
      .finally(() => setLoading(false));
  };

  const handleDelete = () => {
    setLoading(true);
    loadApi(`project/delete_project/${selected.id}`, true, "delete")
      .then((response) => {
        getData();
        setEditableFieldsDisabled(true);
        clearFields();
        handleCloseDelete();
        setSelected(null);
        showSnack(response.data.message, "success");
      })
      .catch((error) => showSnack(error.message, "error"))
      .finally(() => setLoading(false));
  };

  const handleUpdate = () => {
    if (!selected) {
      showSnack("Por favor seleccione un cliente antes de continuar", "warning");
      return;
    }
    setEditableFieldsDisabled(false);
  };

  const clearFields = () => {
    setValue("project_name", "");
    setValue("address", "");
    setValue("city", "");
    setValue("name_contact", "");
    setValue("email", "");
    setValue("notarie_id", "");
    setValue("bank_id", "");
    setValue("trust_id", "");
    setValue("constructor_id", "");
    setPhasesXProject([]);
  };

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    if (newValue) {
      setValue("project_name", newValue.project_name);
      setValue("address", newValue.address);
      setValue("city", newValue.city);
      setValue("name_contact", newValue.name_contact || "");
      setValue("email", newValue.email);
      setValue("notarie_id", newValue.Notary ? newValue.Notary.id : "");
      setValue("bank_id", newValue.Bank ? newValue.Bank.id : "");
      setValue("trust_id", newValue.Trust ? newValue.Trust.id : "");
      setValue("constructor_id", newValue.Constructor ? newValue.Constructor.id : "");
      setEditableFieldsDisabled(true);
      getPhasesByProject(newValue.id);
    } else {
      clearFields();
    }
  };

  const handleOpenDelete = () => setOpenModal(true);
  const handleCloseDelete = () => {
    setOpenModal(false)
    setItemToDelete(null)
  };

  const isProjectCompleted = (phases) => {
    if (!phases || phases.length === 0) {
      return false;
    }
    return phases.every(phase => phase.status);
  };




  const handleActivateCreatePhase = () => {
    if (!selected) {
      showSnack("Seleccione un proyecto primero antes de crear una fase", "warning");
      return;
    }
    setNewPhase({ phases_id: "", project_id: selected.id });
    setOpenCreatePhase(true);
  };

  const handleCreatePhase = async () => {
    setLoading(true);
    loadApi("phases_project", true, "post", newPhase)
      .then((response) => {
        showSnack(response.data.message, "success");
        getPhasesByProject(selected.id);
        setOpenCreatePhase(false);
        clearPhaseFields();
      })
      .catch((error) => showSnack(error.message, "error"))
      .finally(() => setLoading(false));
  };

  const handleSaveEditPhase = async (data) => {
    setLoading(true);
    loadApi(`phases_project/update_phases_project/${currentEditPhase.id}`, true, "put", { ...currentEditPhase, project_id: selected.id })
      .then((response) => {
        showSnack(response.data.message, "success");
        getPhasesByProject(selected.id);
        setOpenEditPhase(false);
        clearPhaseFields();
      })
      .catch((error) => showSnack(error.message, "error"))
      .finally(() => setLoading(false));
  };

  const handleOpenEditPhase = (row) => {
    setCurrentEditPhase(row);
    setOpenEditPhase(true);
  };



  const handleOpenDeletePhase = (item) => {
    setItemToDelete(item);
    setOpenModal(true);
  };


  const handleDeletePhase = async () => {
    setLoading(true);
    loadApi(`phases_project/delete_phases_project/${itemToDelete.id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success");
        setPhasesXProject(phasesXProject.filter((item) => item.id !== itemToDelete.id));
        setOpenModal(false);
        setItemToDelete(null)
      })
      .catch((error) => showSnack(error.message, "error"))
      .finally(() => setLoading(false));
  };

  const handleOpenStepperModal = () => setOpenStepperModal(true);
  const handleCloseStepperModal = () => setOpenStepperModal(false);

  const clearPhaseFields = () => {
    setPhaseValue("phases_id", "");
  };

  const filteredData = useMemo(() => {
    return phasesXProject.filter((item) => {
      return item.Phase && item.Phase.phaseName
        ? item.Phase.phaseName.toLowerCase().includes(search.toLowerCase())
        : false;
    });
  }, [phasesXProject, search]);

  const columns = [
    { name: "Orden", selector: (row) => row.orderNumber, sortable: true },
    { name: "Fase", selector: (row) => row.Phase ? row.Phase.phaseName : "", sortable: true },
    {
      name: "Acciones",
      cell: (row) => (
        <>
          <IconButton color="primary" onClick={() => handleOpenEditPhase(row)}>
            <EditIcon />
          </IconButton>
          <IconButton color="secondary" onClick={() => handleOpenDeletePhase(row)}>
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
        </>
      ),
    },
  ];

  const renderStepper = () => (
    <Stepper orientation="vertical">
      {phasesXProject.map((phase) => (
        <Step key={phase.id} completed={phase.status}>
          <StepButton>
            <StepLabel>{phase?.Phase?.phaseName}</StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );

  return (
    <MenuView>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Box
        sx={{
          height: "100%",
          backgroundColor: "#FFFF",
          borderRadius: ".5em",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          overflow: "auto",
        }}
      >
        <Box sx={{ padding: "1em" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            {!creating && (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Stack sx={{ width: "100%" }}>
                  <Stack sx={{ width: "100%" }}>
                    <Typography sx={{ mb: 1 }}>Buscar proyectos</Typography>
                    <Autocomplete
                      options={data}
                      value={selected}
                      getOptionLabel={(option) => option.project_name}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      noOptionsText={"No hay proyectos disponibles"}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField {...params} label="" placeholder="Seleccione un proyecto..." variant="outlined" />
                      )}
                    />
                  </Stack>
                </Stack>
              </Box>
            )}
            {creating && (
              <Typography variant="h6" component="h2" align="center">
                Crear Proyecto
              </Typography>
            )}
            <Stack>
              <Grid container spacing={2}>
                {!selected && editableFieldsDisabled && !creating && (
                  <Stack sx={{ width: "100%", pl: 2 }}>
                    <Alert severity="warning" sx={{ maxWidth: "100%", justifyContent: "center", mt: 2 }}>
                      Seleccione un proyecto primero o cree uno a continuación.
                    </Alert>
                  </Stack>
                )}
                {(selected || !editableFieldsDisabled || creating) && (
                  <>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="project_name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Nombre de proyecto"
                            disabled={editableFieldsDisabled}
                            error={!!errors.project_name}
                            helperText={errors.project_name?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="address"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Dirección"
                            disabled={editableFieldsDisabled}
                            error={!!errors.address}
                            helperText={errors.address?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="city"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Ciudad"
                            disabled={editableFieldsDisabled}
                            error={!!errors.city}
                            helperText={errors.city?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="name_contact"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Nombre de contacto"
                            disabled={editableFieldsDisabled}
                            error={!!errors.name_contact}
                            helperText={errors.name_contact?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Email"
                            disabled={editableFieldsDisabled}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth error={!!errors.notarie_id} required>
                        <Controller
                          name="notarie_id"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              options={notaries}
                              value={notaries.find((option) => option.id === value) || null}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              noOptionsText={"No hay notarias disponibles"}
                              getOptionLabel={(option) => option.name || ""}
                              onChange={(event, newValue) => onChange(newValue ? newValue.id : "")}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Seleccione una notaria..."
                                  sx={textFieldStyle(!editableFieldsDisabled)}
                                />
                              )}
                              disabled={editableFieldsDisabled}
                            />
                          )}
                        />
                        <FormHelperText>{errors.notarie_id?.message}</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth error={!!errors.bank_id} required>
                        <Controller
                          name="bank_id"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              options={banks}
                              value={banks.find((option) => option.id === value) || null}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              noOptionsText={"No hay bancos disponibles"}
                              getOptionLabel={(option) => option.bank_name || ""}
                              onChange={(event, newValue) => onChange(newValue ? newValue.id : "")}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Seleccione un banco..."
                                  sx={textFieldStyle(!editableFieldsDisabled)}
                                />
                              )}
                              disabled={editableFieldsDisabled}
                            />
                          )}
                        />
                        <FormHelperText>{errors.bank_id?.message}</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth error={!!errors.trust_id} required>
                        <Controller
                          name="trust_id"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              options={trusts}
                              value={trusts.find((option) => option.id === value) || null}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              noOptionsText={"No hay fiducias disponibles"}
                              getOptionLabel={(option) => option.name || ""}
                              onChange={(event, newValue) => onChange(newValue ? newValue.id : "")}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Seleccione una fiducia..."
                                  sx={textFieldStyle(!editableFieldsDisabled)}
                                />
                              )}
                              disabled={editableFieldsDisabled}
                            />
                          )}
                        />
                        <FormHelperText>{errors.trust_id?.message}</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl fullWidth error={!!errors.constructor_id} required>
                        <Controller
                          name="constructor_id"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              options={constructors}
                              value={constructors.find((option) => option.id === value) || null}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              noOptionsText={"No hay constructores disponibles"}
                              getOptionLabel={(option) => option.name || ""}
                              onChange={(event, newValue) => onChange(newValue ? newValue.id : "")}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  placeholder="Seleccione un constructor..."
                                  sx={textFieldStyle(!editableFieldsDisabled)}
                                />
                              )}
                              disabled={editableFieldsDisabled}
                            />
                          )}
                        />
                        <FormHelperText>{errors.constructor_id?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Stack>

            <Grid container spacing={2} sx={{ justifyContent: "end", mt: 2 }}>
              {selected && editableFieldsDisabled && (
                <>
                  <Grid item xs={12} sm={6} md={4}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleOpenDelete}
                      startIcon={<DeleteIcon />}
                      fullWidth
                    >
                      Eliminar este eproyecto
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Button
                      variant="contained"
                      onClick={handleUpdate}
                      startIcon={<EditIcon />}
                      fullWidth
                    >
                      Actualizar este proyecto
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleOpenStepperModal}
                      fullWidth
                    // startIcon={<AddIcon />}
                    >
                      Ver estado del proyecto
                    </Button>
                  </Grid>
                </>
              )}
              {selected && !editableFieldsDisabled && (
                <Grid container spacing={1} sx={{ marginLeft: "1%" }}>
                  <Grid item xs={12} md={8} lg={10}>
                    <BackButton setEditableFieldsDisabled={setEditableFieldsDisabled} />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleSaveEdit)}
                      sx={{ width: "100%" }}
                      startIcon={<SaveIcon />}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              )}
              {!selected && editableFieldsDisabled && !creating && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleActivateCreate}
                  sx={{ width: "auto" }}
                  type="submit"
                  startIcon={<AddIcon />}
                >
                  Crear proyecto
                </Button>
              )}
              {!selected && !editableFieldsDisabled && (
                <Grid container spacing={1} sx={{ marginLeft: "1%" }}>
                  <Grid item xs={12} md={8} lg={10}>
                    <BackButton
                      setEditableFieldsDisabled={setEditableFieldsDisabled}
                      setCreating={setCreating}
                      clearFields={clearFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleCreate)}
                      sx={{ width: "100%" }}
                      type="submit"
                      startIcon={<DoneIcon />}
                    >
                      Crear ahora
                    </Button>
                  </Grid>
                </Grid>

              )}
            </Grid>

            {selected && (

              <Fade in={true}>

                <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
                  <Grid container spacing={2} sx={{ textAlign: "center", alignItems: "end" }}>
                    <Grid item xs={12} sm={8} md={8} lg={8}>
                      <Stack spacing={2}>
                        <TextField
                          variant="outlined"
                          placeholder="Buscar..."
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={8} md={4} lg={4}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={handleActivateCreatePhase}
                        startIcon={<AddIcon />}
                      >
                        Insertar fase
                      </Button>
                    </Grid>
                  </Grid>
                  <Box mt={3}>
                    {filteredData.length === 0 ? (
                      <Box p={2} textAlign="center">
                        <Typography variant="body1">Datos no encontrados</Typography>
                      </Box>
                    ) : (
                      <DataTable
                        title="Fases por proyecto"
                        columns={columns}
                        data={filteredData}
                        pagination
                        dense
                        responsive
                        customStyles={tableStyle}
                      />
                    )}
                  </Box>
                  <Stack>
                    <ModalDocument name={"Agregar documento al proyecto"} endpointGet={`project_generic_document/${selected?.id}`} endpoint={`project_generic_document/${selected?.id}`} endpointPut={`project_generic_document/update_document`} endpointDelete={"project_generic_document/delete_document"} />

                    <BackButton
                      setEditableFieldsDisabled={setEditableFieldsDisabled}
                      setCreating={setCreating}
                      clearFields={clearFields}
                      setSelected={setSelected}
                    />
                  </Stack>

                </Paper>

              </Fade>

            )}
          </Box>
        </Box>

        <Modal open={openEditPhase} onClose={() => setOpenEditPhase(false)}>
          <Box sx={modalStyle}>
            <Typography variant="h6" component="h2">
              Editar fase
            </Typography>
            <Stack spacing={2}>
              <FormControl>
                <Autocomplete
                  options={phases}
                  getOptionLabel={(option) => option.phaseName}
                  value={phases.find((option) => option.id === currentEditPhase.phases_id) || null}
                  onChange={(event, newValue) => {
                    setCurrentEditPhase((prevEdit) => ({
                      ...prevEdit,
                      phases_id: newValue ? newValue.id : "",
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Fase" variant="outlined" />}
                />
                <FormHelperText>Seleccione una fase</FormHelperText>
              </FormControl>
              <Button variant="contained" onClick={handleSaveEditPhase} startIcon={<SaveIcon />}>
                Guardar
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Modal open={openCreatePhase} onClose={() => setOpenCreatePhase(false)}>
          <Box sx={modalStyle}>
            <Typography id="create-modal-title" variant="h6" component="h2">
              Insertar nueva fase
            </Typography>
            <Stack spacing={2}>
              <FormControl>
                <Autocomplete
                  options={phases}
                  getOptionLabel={(option) => option.phaseName}
                  value={phases.find((option) => option.id === newPhase.phases_id) || null}
                  onChange={(event, newValue) => {
                    setNewPhase((prevPhase) => ({
                      ...prevPhase,
                      phases_id: newValue ? newValue.id : "",
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Fase" variant="outlined" />}
                />
                <FormHelperText>Seleccione una fase</FormHelperText>
              </FormControl>
              <Button variant="contained" onClick={handleCreatePhase} startIcon={<AddIcon />}>
                Crear
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Modal open={openStepperModal} onClose={handleCloseStepperModal}>
          <Box sx={stepperModalStyle}>
            <Typography variant="h6" component="h2">
              Estado del Proyecto
            </Typography>
            {renderStepper()}
            <Typography variant="h6" component="h2" color={isProjectCompleted(phasesXProject) ? "success.main" : "error.main"} sx={{ mt: 2 }}>
              {isProjectCompleted(phasesXProject) ? "El proyecto está completado" : "El proyecto está incompleto"}
            </Typography>
          </Box>
        </Modal>
        <Modal open={openModal} aria-labelledby="confirmation-modal-title" aria-describedby="confirmation-modal-description">
          <Box sx={modalStyle}>
            <Typography id="confirmation-modal-title" variant="h6" component="h2">
              {itemToDelete ? "¿Estás seguro de eliminar esta fase?" : "¿Estás seguro de eliminar el proyecto?"}
            </Typography>
            <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
              Esta acción no se puede deshacer.
            </Typography>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "row", gap: "1em" }}>
              <Button variant="outlined" color="error" onClick={handleCloseDelete} sx={{ width: "100%" }}>
                Cancelar
              </Button>
              <Button variant="contained" onClick={itemToDelete ? handleDeletePhase : handleDelete} sx={{ width: "100%" }}>
                Confirmar
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </MenuView>
  );
}
