import React, { useEffect, useState } from "react";
import { Autocomplete, TextField, Grid } from "@mui/material";
import useApi from "../../../../hooks/useApi";
import Inputs from "../input";

export default function Compradores({ rowData, showSnack }) {
  const [data, setData] = useState({ identifier_type: [] });
  const { loadApi } = useApi();
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const getData = async () => {
    setLoading(true);
    loadApi(`third_parties/${rowData.third_id}`, true, "get")
      .then((response) => {
        setData(response.data);
        setSelectedValue(response.data.identifier_type || "");
      })
      .catch((error) => {
        setData({ identifier_type: [] });
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Grid container spacing={2} sx={{ paddingTop: "5%" }}>
      <Grid item xs={12} sm={6} md={4}>
        <Autocomplete
          options={Array.isArray(data.identifier_type) ? data.identifier_type : []}
          isOptionEqualToValue={(option, value) => option.identifier_type === value.identifier_type}
          getOptionLabel={(option) => option || ""}
          noOptionsText={"No hay listas disponibles"}
          value={selectedValue}
          onChange={(event, newValue) => setSelectedValue(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tipo de identificación"
              variant="outlined"
              placeholder="Seleccione una opción..."
            />
          )}
          fullWidth
          clearOnEscape
          readOnly
        />
      </Grid>
      <>
        <Inputs infoLabel={"Número de identificación"} dato={data?.identifier} />
        <Inputs infoLabel={"Nombre"} dato={data?.name} />
        <Inputs infoLabel={"Email"} dato={data?.email} />
        <Inputs infoLabel={"Dirección"} dato={data?.address} />
        <Inputs infoLabel={"Ciudad"} dato={data?.city} />
        <Inputs infoLabel={"Teléfono"} dato={data?.phone} />
        <Inputs infoLabel={"Nombre de Contacto"} dato={data?.contact_name} />
        <Inputs infoLabel={"Email de Contacto"} dato={data?.contact_email} />
      </>
    </Grid>
  );
}
