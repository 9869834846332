import { Stack, TextField } from '@mui/material';

const Step3 = ({ row }) => {
    return (
        <Stack spacing={2}>
            <TextField
                label="Estado"
                value={row?.status?.meaning || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Tipo negocio *"
                value={row?.businessType?.meaning || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
        </Stack>
    );
};

export default Step3;
