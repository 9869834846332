import { Modal, Fade, Box,IconButton } from "@mui/material";
import Navtaps from "./navtaps";
import CloseIcon from '@mui/icons-material/Close';

const ModalComponent = ({ open, handleClose, showSnack, updateList, rowData }) => {
  
  return (
    <Modal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: "80%",
            maxWidth: "90vw",
            minHeight: "80vh",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
         <Navtaps rowData={rowData} updateList={updateList} handleClose={handleClose} showSnack={showSnack}  />
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalComponent;