import { Box, Stack, TextField } from '@mui/material';

const Step5 = ({ row }) => {
    return (
        <Stack spacing={2}>
            <TextField
                label="Perito"
                value={row?.Perito?.name || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Abogado"
                value={row?.Lawyer?.name || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Notaria"
                value={row?.Notary?.name || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            {row?.Notary && (
                <TextField
                    label="Contacto del banco"
                    value={row?.NotariesContact?.contact_name || ""}
                    variant="outlined"
                    readOnly
                    fullWidth
                />
            )}
            {row?.NotariesContact && (
                <Stack direction={{ md: "row", xs: "column" }} spacing={2} sx={{ margin: 0 }}>
                    <Box sx={{ flex: 1 }}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            value={row?.NotariesContact?.email}
                            readOnly
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <TextField
                            label="Teléfono"
                            variant="outlined"
                            value={row?.NotariesContact?.phone}
                            readOnly
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <TextField
                            label="Cargo"
                            variant="outlined"
                            value={row?.NotariesContact?.position}
                            readOnly
                            fullWidth
                        />
                    </Box>
                </Stack>
            )}


        </Stack>
    );
};

export default Step5;
