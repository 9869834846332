import GroupsIcon from '@mui/icons-material/Groups';
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import LogoutIcon from '@mui/icons-material/Logout';

const globalOptions = [

];

const headerOptions = {
    logged: [
        {
            title: "Sedes",
            icon: HolidayVillageIcon,
            route: "/sedes",
            roles: ["superadmin", "admin"]
        },
        {
            title: "Usuarios",
            icon: GroupsIcon,
            route: "/usuarios",
            roles: ["superadmin"]
        },
        {
            title: "Cerrar Sesión",
            route: "/logout",
            icon: LogoutIcon
        },
    ],
    default: [
        ...globalOptions,
    ]
}

export default headerOptions;