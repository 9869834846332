import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  Box,
  IconButton,
  Stack,
  TextField,
  Typography,
  Modal,
  Button,
  Autocomplete,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  InputAdornment,
  Alert,
  Paper,
  Fade,
  Grid
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from '@mui/icons-material/Search';
import MenuView from "../../components/MenuView";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #FFFF",
  borderRadius: ".5em",
  boxShadow: 24,
  p: 4,
};

const tableStyle = {
  header: { style: { backgroundColor: "#FFFF", fontSize: "16px", fontWeight: "bold" } },
  rows: { style: { fontSize: "14px", fontWeight: "400" } },
  columns: { style: { width: "150px", maxWidth: "150px", minWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } },
};

export default function Lists() {
  const { loadApi } = useApi();
  const { showSnack, msgSB, openSB, typeSB } = useSnack();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modals, setModals] = useState({ edit: false, insert: false, createList: false, deleteItem: false, deleteList: false });
  const [currentEdit, setCurrentEdit] = useState({ id: "", value: "", meaning: "", status: true });
  const [newItem, setNewItem] = useState({ value: "", meaning: "", status: true });
  const [newList, setNewList] = useState({ name: "", description: "", data: [] });
  const [descripcion, setDescripcion] = useState("");
  const [search, setSearch] = useState("");
  const [listaOptions, setListaOptions] = useState([]);
  const [selectedList, setSelectedList] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);

  const toggleModal = (type, value) => setModals(prev => ({ ...prev, [type]: value }));

  const handleEditChange = ({ target: { name, value } }) => setCurrentEdit(prev => ({ ...prev, [name]: name === "status" ? value === "1" : value }));

  const handleOpenModal = (type, item = null) => {
    if (item) setItemToDelete(item);
    toggleModal(type, true);
  };

  const handleCloseModal = type => toggleModal(type, false);

  const handleSaveEdit = () => {
    setLoading(true);
    loadApi(`list/update_listValue/${currentEdit.id}`, true, "put", {
      value: currentEdit.value,
      meaning: currentEdit.meaning,
      status: currentEdit.status
    }).then(() => {
      setData(prev => prev.map(item => item.id === currentEdit.id ? { ...item, ...currentEdit, status: currentEdit.status ? 1 : 0 } : item));
      showSnack("Elemento editado correctamente", "success");
    }).catch(error => showSnack(error.message, "error")).finally(() => setLoading(false));
    handleCloseModal('edit');
  };

  const handleConfirmDelete = () => {
    setLoading(true);
    loadApi(`list/delete_listValue/${itemToDelete.id}`, true, "delete").then((response) => {
      setData(prev => prev.filter(item => item.id !== itemToDelete.id));
      showSnack(response.data.message, "success");
    }).catch(error => showSnack(error.message, "error")).finally(() => setLoading(false));
    handleCloseModal('deleteItem');
  };

  const handleConfirmDeleteList = () => {
    setLoading(true);
    loadApi(`list/delete_list/${selectedList.id}`, true, "delete").then((response) => {
      setListaOptions(prev => prev.filter(list => list.id !== selectedList.id));
      setSelectedList(null);
      setData([]);
      setDescripcion("");
      showSnack(response.data.message, "success");
    }).catch(error => showSnack(error.message, "error")).finally(() => setLoading(false));
    handleCloseModal('deleteList');
  };

  const handleInsert = () => {
    if (!selectedList) return showSnack("Por favor, selecciona una lista primero", "warning");
    const newItemData = { value: newItem.value, meaning: newItem.meaning, status: newItem.status };
    setLoading(true);
    loadApi(`list/create_listValue/${selectedList.id}`, true, "post", newItemData).then(({ data: { newListValues } }) => {
      if (newListValues) {
        const newListData = { id: newListValues.id, value: newListValues.value, meaning: newListValues.meaning, status: newListValues.status };
        setData(prev => [...prev, newListData]);
        setListaOptions(prev => prev.map(list => list.id === selectedList.id ? { ...list, Lists: [...list.Lists, newListData] } : list));
        setNewItem({ value: "", meaning: "", status: true });
        showSnack("Elemento insertado correctamente", "success");
        handleCloseModal('insert');
      }
    }).catch(error => showSnack(error.message, "error")).finally(() => setLoading(false));
  };

  const handleListChange = (event, newValue) => {
    setSelectedList(newValue);
    setData(newValue ? newValue.Lists || [] : []);
    setDescripcion(newValue ? newValue.description : "");
  };
   
  const getLists = async () => {
    setLoading(true);
    loadApi("list/all_lists", true, "get").then(({ data }) => {
      setListaOptions(data || []);
      if (data.length > 0 && !selectedList) {
        setSelectedList(data[0]);
        setData(data[0].Lists || []);
        setDescripcion(data[0].description);
       
      }
    }).catch(error => showSnack(error.message, "error")).finally(() => setLoading(false));
  };

  useEffect(() => { getLists(); }, []);

  const handleCreateList = () => {
    setLoading(true);
    loadApi("list", true, "post", { name: newList.name, description: newList.description }).then(({ data: { newList } }) => {
      showSnack("Lista creada", "success");
      getLists();
      setSelectedList(newList);
      setData([]);
      setDescripcion(newList.description);
      handleCloseModal('createList');
    }).catch(error => showSnack(error.message, "error")).finally(() => setLoading(false));
  };

  const filteredData = useMemo(() => data.filter(item => item.value.toLowerCase().includes(search.toLowerCase()) || item.meaning.toLowerCase().includes(search.toLowerCase())), [data, search]);

  const columns = [
    { name: "Valor", selector: row => row.value, sortable: true },
    { name: "Significado", selector: row => row.meaning, sortable: true },
    { name: "Activo", selector: row => row.status ? "Sí" : "No", sortable: true },
    {
      name: "Acciones",
      cell: row => (
        <>
          <IconButton color="primary" onClick={() => { setCurrentEdit(row); handleOpenModal('edit'); }}>
            <EditIcon />
          </IconButton>
          <IconButton color="secondary" onClick={() => handleOpenModal('deleteItem', row)}>
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
        </>
      )
    }
  ];

  return (
    <MenuView>
      <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Box sx={{ height: "100%", backgroundColor: "#FFFF", borderRadius: ".5em", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
        <Stack pt={2} direction="row" justifyContent="space-between" alignItems="center" padding="1em" boxSizing="border-box" gap={2}>
          <Stack width={{ xs: "100%", sm: "45%", lg: "45%" }}>
            <Typography variant="body1" component="p" sx={{ marginBottom: 1, fontSize: 14, fontWeight: "bold" }}>
              Nombre lista
            </Typography>
            <Autocomplete 
              options={listaOptions}
              getOptionLabel={option => option.name}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              noOptionsText={"No hay listas disponibles"}
              value={selectedList} onChange={handleListChange}
              renderInput={params => <TextField {...params}
                 variant="outlined"
                 placeholder="Seleccione una opción..." />}
              fullWidth clearOnEscape />
          </Stack>
          <Stack width={{ xs: "100%", sm: "45%", lg: "45%" }}>
            <Typography variant="body1" component="p" sx={{ marginBottom: 1, fontSize: 14, fontWeight: "bold" }}>
              Descripción
            </Typography>
            <TextField id="descripcion" value={descripcion} variant="outlined" InputProps={{ readOnly: true }} fullWidth />
          </Stack>
        </Stack>
        <Stack pt={2} direction="column" padding="1em" boxSizing="border-box" gap={2}>
          <Stack width="100%" gap={2} direction="column">
            {!selectedList && (
              <Alert severity="warning" sx={{ maxWidth: '100%', justifyContent: 'center' }}>
                Seleccione una lista o cree una a continuación.
              </Alert>
            )}
            <Grid  container spacing={2} sx={{ width: "100%" }}>
              {selectedList && (
                <>
                <Grid item xs={12} sm={6} md={4}>
                  <Button variant="contained" onClick={() => handleOpenModal('insert')} fullWidth>
                    Insertar elementos
                  </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                  <Button  startIcon={<DeleteIcon />} variant="outlined" color="error" onClick={() => handleOpenModal('deleteList')} fullWidth>
                    Eliminar esta lista
                  </Button>
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={6} md={4}>
              <Button variant="contained"  onClick={() => handleOpenModal('createList')} fullWidth>
                Crear nueva lista
              </Button>
              </Grid>
            </Grid>
          </Stack>
          {selectedList && (
            <Fade in={selectedList} timeout={500}>
              <Paper elevation={2} sx={{ p: 2, borderRadius: '8px' }}>
                <TextField variant="outlined" placeholder="Buscar..." value={search} onChange={e => setSearch(e.target.value)}
                  InputProps={{ startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>) }} fullWidth sx={{ marginBottom: 2 }} />
                {filteredData.length === 0 ? (
                  <Box p={2} textAlign="center" sx={{ mt: 2 }}>
                    <Typography variant="body1">
                      {search ? "No se encontraron los datos buscados" : "No hay datos disponibles para mostrar en la tabla"}
                    </Typography>
                  </Box>
                ) : (
                  <Box sx={{ pt: 5, pb: 2 }}>
                    <DataTable title="Valores" columns={columns} data={filteredData} pagination dense responsive customStyles={tableStyle} />
                  </Box>
                )}
              </Paper>
            </Fade>
          )}
        </Stack>
        <Modal open={modals.edit} onClose={() => handleCloseModal('edit')}>
          <Box sx={modalStyle}>
            <Typography variant="h6" component="h2"sx={{mb:3}} >Editar elemento</Typography>
            <Stack spacing={2}>
              <TextField label="Valor" name="value" value={currentEdit.value} onChange={handleEditChange} />
              <TextField label="Significado" name="meaning" value={currentEdit.meaning} onChange={handleEditChange} />
              <Select label="Activo" name="status" value={currentEdit.status ? "1" : "0"} onChange={handleEditChange}>
                <MenuItem value="1">Sí</MenuItem>
                <MenuItem value="0">No</MenuItem>
              </Select>
              <Button variant="contained" onClick={handleSaveEdit}>Guardar</Button>
            </Stack>
          </Box>
        </Modal>
        <Modal open={modals.insert} onClose={() => handleCloseModal('insert')}>
          <Box sx={modalStyle}>
            <Typography variant="h6" component="h2" sx={{mb:3}}>Insertar elemento</Typography>
            <Stack spacing={2}>
              <TextField label="Valor" name="value" value={newItem.value} onChange={e => setNewItem(prev => ({ ...prev, value: e.target.value }))} />
              <TextField label="Significado" name="meaning" value={newItem.meaning} onChange={e => setNewItem(prev => ({ ...prev, meaning: e.target.value }))} />
              <Button variant="contained" fullWidth  onClick={handleInsert}>Insertar</Button>
            </Stack>
          </Box>
        </Modal>
        <Modal open={modals.createList} onClose={() => handleCloseModal('createList')}>
          <Box sx={modalStyle}>
            <Typography variant="h6" component="h2" sx={{mb:3}}>Crear nueva lista</Typography>
            <Stack spacing={2}>
              <TextField label="Nombre lista" name="name" value={newList.name} onChange={e => setNewList(prev => ({ ...prev, name: e.target.value }))} />
              <TextField label="Descripción" name="description" value={newList.description} onChange={e => setNewList(prev => ({ ...prev, description: e.target.value }))} />
              <Button variant="contained" fullWidth onClick={handleCreateList}>Crear</Button>
            </Stack>
          </Box>
        </Modal>
        <Modal open={modals.deleteItem} onClose={() => handleCloseModal('deleteItem')} aria-labelledby="confirmation-modal-title" aria-describedby="confirmation-modal-description">
          <Box sx={modalStyle}>
            <Typography id="confirmation-modal-title" variant="h6" sx={{mb:3}} component="h2">¿Estás seguro de eliminar el elemento?</Typography>
            <Typography id="confirmation-modal-description" sx={{ mb: 2 }}>Esta acción no se puede deshacer.</Typography>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "row", gap: "1em" }}>
              <Button variant="outlined" fullWidth color="error" onClick={() => handleCloseModal('deleteItem')} >Cancelar</Button>
              <Button variant="contained" fullWidth onClick={handleConfirmDelete} >Confirmar</Button>
            </Box>
          </Box>
        </Modal>
        <Modal open={modals.deleteList} onClose={() => handleCloseModal('deleteList')} aria-labelledby="confirmation-modal-title" aria-describedby="confirmation-modal-description">
          <Box sx={modalStyle}>
            <Typography id="confirmation-modal-title" variant="h6" sx={{mb:3}} component="h2">¿Estás seguro de eliminar la lista?</Typography>
            <Typography id="confirmation-modal-description" sx={{ mb: 2 }}>Esta acción no se puede deshacer.</Typography>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "row", gap: "1em" }}>
              <Button variant="outlined" color="error" onClick={() => handleCloseModal('deleteList')} sx={{ width: "100%" }}>Cancelar</Button>
              <Button variant="contained" fullWidth onClick={handleConfirmDeleteList} >Confirmar</Button>
            </Box>
          </Box>
        </Modal>
      </Box>
      <SnackAlert />
    </MenuView>
  );
}
