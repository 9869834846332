import { useState } from "react";
import useApi from "../hooks/useApi";

const useContactManager = () => {
    const [loading, setLoading] = useState(false);
    const { loadApi } = useApi();


    const getContactConstructor = async (id) => {
        setLoading(true);
        try {
            const response = await loadApi(`constructor/contacts/${id}`, true, "get");
            return response.data;
        } catch (error) {

        } finally {
            setLoading(false);
        }
    };

    const getContactBanks = async (id) => {
        setLoading(true);
        try {
            const response = await loadApi(`banks/contacts/${id}`, true, "get");
            return response.data;
        } catch (error) {

        } finally {
            setLoading(false);
        }
    };

    const getContactNotaries = async (id) => {
        setLoading(true);
        try {
            const response = await loadApi(`notaries/contacts/${id}`, true, "get");
            return response.data;
        } catch (error) {

        } finally {
            setLoading(false);
        }
    };


    return {
        getContactConstructor,
        getContactBanks,
        getContactNotaries,
        loading,

    };
};

export default useContactManager;
