import React, { useState } from "react";
import { Modal, Fade, Box, IconButton, TextField, Typography, Button, Grid } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useApi from "../../../../hooks/useApi";

// Definición del esquema de validación
const schema = yup.object().shape({
  title: yup.string().required("Requerido"),
  description: yup.string().required("Requerido"),
});

// Componente del modal
const ModalComponent = ({ open, handleClose, showSnack, rowData, handleHistorialUpdate, isPhaseModal }) => {
  const { control, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const [loading, setLoading] = useState(false);
  const { loadApi } = useApi();

  const handleCreate = async (formData) => {
    const dataToSend = {
      writing_id: rowData.id,
      title: formData.title,
      description: formData.description,
    };

    setLoading(true);
    loadApi(`history/`, true, "post", dataToSend)
      .then((response) => {
        handleHistorialUpdate(); // Actualizar historial
        showSnack(response.data.message, "success");
        reset(); // Limpiar los campos
        handleClose(); // Cerrar modal después de limpiar
      })
      .catch((error) => showSnack(error.message, "error"))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleModalClose = () => {
    reset(); // Limpiar los campos al cerrar el modal
    handleClose();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: "50%",
            maxWidth: "90vw",
            minHeight: "50vh",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleModalClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Contenido del modal */}
          <Typography variant="body1" component="p" sx={{ marginBottom: 5, marginTop: 2, textAlign: "center", fontSize: 20, fontWeight: "bold" }}>
            Historial de seguimientos
          </Typography>
          <form onSubmit={handleSubmit(handleCreate)}>
            <Controller
              name="title"
              control={control}
              defaultValue={isPhaseModal ? "En espera" : ""} // Valor por defecto basado en el prop
              render={({ field }) => (
                <TextField
                  {...field}
                  label={"Título"}
                  variant="outlined"
                  fullWidth
                  error={!!errors.title}
                  helperText={errors.title ? errors.title.message : ""}
                  sx={{ marginBottom: 2 }} // Espaciado adicional
                  disabled={isPhaseModal} // Deshabilitado si es un modal de fase
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={"Descripción"}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={6}
                  error={!!errors.description}
                  helperText={errors.description ? errors.description.message : ""}
                />
              )}
            />
            <Grid container spacing={2} sx={{ marginY: "20px" }}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={handleModalClose}
                >
                  Cerrar
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  fullWidth
                  type="submit" // Cambiado a tipo "submit" para manejar el envío del formulario
                  disabled={loading} // Deshabilitar el botón mientras se carga
                >
                  {loading ? "Guardando..." : "Guardar"}
                </Button>
              </Grid>
            </Grid>
          </form>
          {/* Fin de contenido */}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalComponent;
