import React, { useEffect, useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Container,
} from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import useApi from "../../../../hooks/useApi";

const FaseDocumentos = ({ rowData, showSnack }) => {
  const [documentosProyecto, setDocumentsProject] = useState([]);
  const [documentosInmueble, setDocumentsInmueble] = useState([]);
  const [loading, setLoading] = useState(false);
  const { loadApi } = useApi();

  const getData = async (endpointGet, setDocuments) => {
    setLoading(true);
    loadApi(endpointGet, true, "get")
      .then((response) => {
        setDocuments(response.data || []);
      })
      .catch((error) => {
        setDocuments([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (rowData?.id) {
      getData(`generic_document/${rowData?.id}`, setDocumentsInmueble);
      getData(
        `project_generic_document/${rowData?.Project?.id}`,
        setDocumentsProject
      );
    }
  }, [rowData]);
  const handleDownload = (url, fileName) => {
    try {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
      showSnack("Error al descargar el archivo.", "error");
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" gutterBottom>
        Documentos del proyecto
      </Typography>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <List>
          {loading ? (
            <ListItem>
              <ListItemText primary="Cargando documentos..." />
            </ListItem>
          ) : documentosProyecto.length > 0 ? (
            documentosProyecto.map((documento) => (
              <ListItem key={documento.name} divider>
                <ListItemText primary={documento.name} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="descargar"
                    onClick={() =>
                      handleDownload(
                        documento.document.url,
                        documento.document.name
                      )
                    }
                  >
                    <DownloadIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No hay documentos disponibles" />
            </ListItem>
          )}
        </List>
      </Paper>

      <Typography variant="h4" gutterBottom>
        Documentos del inmueble
      </Typography>
      <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
        <List>
          {loading ? (
            <ListItem>
              <ListItemText primary="Cargando documentos..." />
            </ListItem>
          ) : documentosInmueble.length > 0 ? (
            documentosInmueble.map((documento) => (
              <ListItem key={documento.name} divider>
                <ListItemText primary={documento.name} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="descargar"
                    onClick={() =>
                      handleDownload(
                        documento.document.url,
                        documento.document.name
                      )
                    }
                  >
                    <DownloadIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No hay documentos disponibles" />
            </ListItem>
          )}
        </List>
      </Paper>
    </Container>
  );
};

export default FaseDocumentos;
