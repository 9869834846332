import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Button, Stack } from "@mui/material";
import useApi from "../../../../hooks/useApi";
import DataTable from "react-data-table-component";
import ModalHistorial from "./modalHistorial";

export default function Fases({ rowData, showSnack, updateList }) {
  const [data, setData] = useState([]);
  const { loadApi } = useApi();
  const [loading, setLoading] = useState(false);
  const [faseActual, setFaseActual] = useState(null);
  const [faseEspecialActive, setEspecialActive] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const tableStyle = {
    header: { style: { backgroundColor: "#FFFF", fontSize: "16px", fontWeight: "bold" } },
    rows: { style: { fontSize: "14px", fontWeight: "400" } },
    columns: { style: { width: "150px", maxWidth: "150px", minWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } },
  };

  const columns = [
    { name: "Orden", selector: row => row.orderNumber, sortable: true },
    { name: "Fase", selector: row => row.phaseName, sortable: true },
    { name: "Días estimados", selector: row => row.phaseDays, sortable: true },
    { name: "Documentos cargados?", selector: row => row.areAllMandatoryDocumentsLoaded ? "Sí" : "No", sortable: true },
  ];

  const conditionalRowStyles = [
    {
      when: row => row.orderNumber === faseActual,
      style: {
        backgroundColor: '#f0f0f0',
        fontWeight: 'bold',
      },
    },
  ];

  const pasarFase = async () => {
    setLoading(true);
    try {
      await loadApi(`follow_up/update_current_phase/${rowData.id}`, true, "put").then((response) => {
        showSnack(response.data.message, "success");
        getFases();
      });
    } catch (error) {
      showSnack(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const faseEspecial = async (formData) => {
    setLoading(true);
    try {
      await loadApi(`follow_up/special_phase/${rowData.id}`, true, "put", formData).then((response) => {
        showSnack(response.data.message, "success");
        getFases();
      });
    } catch (error) {
      showSnack(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (rowData && rowData.id) {
      getFases();
    }
  }, [rowData]);

  const getFases = async () => {
    setLoading(true);
    try {
      const response = await loadApi(`follow_up/property/${rowData.id}`, true, "get");
      if (response.data.projects[0].phases.length > 0) {
        setFaseActual(response.data.current_phase);
        setEspecialActive(response.data.special_phase);
        setData(response.data.projects[0].phases);
      } else {
        setData([]);
      }
    } catch (error) {
      showSnack(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleHistorialUpdate = async (formData) => {
    await faseEspecial(formData);
    handleCloseModal(); // Cerrar el modal después de guardar
  };

  return (
    <>
      <Grid container spacing={2} sx={{ marginBottom: 1, fontSize: 14, fontWeight: "bold", textAlign: "center", alignItems: "center" }}>
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" component="p" sx={{ marginBottom: 1, fontSize: 14 }}>
            Nomenclatura:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          {rowData?.nomenclature && rowData.nomenclature.length > 0 && (
            <Typography variant="body1" component="p" sx={{ marginBottom: 1, fontSize: 14, fontWeight: "bold" }}>
              {rowData.nomenclature}
            </Typography>
          )}
        </Grid>
      </Grid>
      {data.length === 0 ? (
        <Box p={2} textAlign="center" sx={{ mt: 2 }}>
          <Typography variant="body1">
            {"No hay datos disponibles para mostrar en la tabla"}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ pt: 2, pb: 2 }}>
          <Stack justifyContent="end" gap={2} direction="row">
            <Button
              variant="outlined"
              onClick={faseEspecialActive === "true" ? () => faseEspecial() : handleOpenModal}
              disabled={loading}
            >
              {faseEspecialActive === "true" ? "Terminar la fase en espera" : "Poner en espera"}
            </Button>

            {faseEspecialActive !== "true" && (
              <Button onClick={pasarFase} disabled={loading}>
                {loading ? "Cargando..." : "Pasar a siguiente fase"}
              </Button>
            )}
          </Stack>


          <DataTable
            title="Valores"
            columns={columns}
            data={data}
            pagination
            dense
            responsive
            customStyles={tableStyle}
            conditionalRowStyles={conditionalRowStyles}
          />

          <ModalHistorial
            open={modalOpen}
            handleClose={handleCloseModal}
            showSnack={showSnack}
            rowData={rowData}
            isPhaseModal={true}
            handleHistorialUpdate={handleHistorialUpdate}
            title="En Espera" // Título por defecto
          />
        </Box>
      )}
    </>
  );
}
