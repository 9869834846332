import React, { useState, useEffect } from 'react';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Paper,
  Container,
  CircularProgress,
  Grid,
} from '@mui/material';
import { Download as DownloadIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import useApi from '../../../../hooks/useApi';
import ModalCargarDocumento from "./modalCargarDocumento";

const FaseDocumentos = ({ rowData, showSnack }) => {
  const [fases, setFases] = useState([]);
  const [writingId, setWritingId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const { loadApi } = useApi();

  const handleDownload = (url, fileName) => {
    try {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      showSnack('Error al descargar el archivo.', 'error');
    }
  };

  const getFases = async () => {
    setLoading(true);
    try {
      const response = await loadApi(`follow_up/property/${rowData.id}`, true, 'get');
      setFases(response.data.projects[0].phases);
      setWritingId(response.data.writingId);
    } catch (error) {
      showSnack(error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (rowData && rowData.id) {
      getFases();
    }
  }, [rowData]);

  const handleOpenUploadModal = (documento) => {
    setSelectedDocument(documento);
    setOpenUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
    setSelectedDocument(null);
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {loading ? (
        <CircularProgress />
      ) : (
        fases.map((fase) => (
          <Paper key={fase.phase_id} elevation={3} sx={{ p: 3, mb: 4 }}>
            <Typography variant="h4" component="h1" gutterBottom>
              {fase.phaseName}
            </Typography>
            <Grid container spacing={2}>
              {fase.duration ? (
                <>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      Duración: {fase.duration ? `${fase.duration} ` : 'Sin especificar'}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    Sin iniciar
                  </Typography>
                </Grid>
              )}
            </Grid>
            <List>
              {fase.documents.length > 0 ? (
                fase.documents.map((documento) => (
                  <ListItem key={documento.id} divider>
                    <ListItemText primary={documento.documentName} />
                    <ListItemSecondaryAction>
                      {documento.loadedDocument?.url ? (
                        <>
                          <IconButton
                            edge="end"
                            aria-label="descargar"
                            onClick={() => handleDownload(documento.loadedDocument.url, documento.loadedDocument.name)}
                          >
                            <DownloadIcon />
                          </IconButton>
                          <IconButton
                            edge="end"
                            aria-label="actualizar"
                            onClick={() => handleOpenUploadModal({ ...documento, fase: fase.phase_id, writingId: writingId })}
                          >
                            <RefreshIcon />
                          </IconButton>
                        </>
                      ) : (
                        <Typography variant="body2" color="textSecondary">
                          No se subió un documento
                        </Typography>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemText primary="No hay documentos disponibles" />
                </ListItem>
              )}
            </List>
          </Paper>
        ))
      )}
      <ModalCargarDocumento
        open={openUploadModal}
        handleClose={handleCloseUploadModal}
        selectedDocument={selectedDocument}
        setSelectedDocument={setSelectedDocument}
        showSnack={showSnack}
        tipo={"update"}
        fase={selectedDocument?.fase}
        handleUpdateList={getFases}
      />
    </Container>
  );
};

export default FaseDocumentos;
