import {
    TextField,
    Grid
} from "@mui/material";

export default function Inputs({ infoLabel, dato }) {
    return (
        <Grid item xs={12} sm={6} md={4}>
            <TextField
                variant="outlined"
                readOnly
                label={infoLabel}
                fullWidth
                sx={{ fontSize: 14, fontWeight: "bold", alignSelf: "flex-end" }}
                value={dato || ""}
            />
        </Grid>
    )
}