import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import MenuView from "../../components/MenuView";
import {
  Box,
  Grid,
  TextField,
  Button,
  Autocomplete,
  Modal,
  IconButton,
  InputAdornment,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  Alert,
  Fade,
  Paper,
} from "@mui/material";
import {
  Search as SearchIcon,
  Visibility,
  VisibilityOff,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Save as SaveIcon,
  Done as DoneIcon,
} from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";
import BackButton from "../../components/buttonBack";

const schema = yup.object().shape({
  username: yup.string().required("Requerido").email("Usuario inválido"),
  name: yup.string().required("Requerido").matches(/^[a-zA-ZÀ-ÿ\s]+$/, "Debe ser solo letras"),
  password: yup.string().notRequired(),
  rol_id: yup.number().required("Requerido"),
  status: yup.string().required("Requerido"),
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #FFFF",
  borderRadius: ".5em",
  boxShadow: 24,
  p: 4,
};

const tableStyle = {
  header: { style: { backgroundColor: "#FFFF", fontSize: "16px", fontWeight: "bold" } },
  rows: { style: { fontSize: "14px", fontWeight: "400" } },
  columns: {
    style: {
      width: "150px",
      maxWidth: "150px",
      minWidth: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
};

const textFieldStyle = (enabled) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: enabled ? "black" : "default",
      borderRadius: "8px",
      transition: "all 0.3s ease",
    },
  },
});

export default function Users() {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState([]);
  const [dataProject, setDataProject] = useState([]);
  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState("");
  const [editableFieldsDisabled, setEditableFieldsDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openInsertModal, setOpenInsertModal] = useState(false);
  const [openRemoveModal, setOpenRemoveModal] = useState(false);
  const [creating, setCreating] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectToRemove, setProjectToRemove] = useState(null);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { loadApi } = useApi();
  const { showSnack, msgSB, openSB, typeSB } = useSnack();

  useEffect(() => {
    getData();
    getProjects();
  }, []);

  const getData = async () => {
    setLoading(true);
    loadApi("users/all_users", true, "get")
      .then((response) => {
        setData(response.data || []);
      })
      .catch(() => {
        setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProjects = async () => {
    loadApi("project/all_projects", true, "get")
      .then((response) => {
        setProjects(response.data || []);
      })
      .catch(() => {
        setProjects([]);
      });
  };

  const handleActivateCreate = () => {
    setEditableFieldsDisabled(false);
    setCreating(true);
    setSelected(null);
  };

  const handleCreate = async (data) => {
    setLoading(true);
    loadApi("users", true, "post", data)
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
        clearFields();
        setCreating(false);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveEdit = (data) => {
    setLoading(true);
    loadApi(`users/update_user/${selected.id}`, true, "put", data)
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    loadApi(`users/delete_user/${selected.id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
        clearFields();
        handleCloseDelete();
        setSelected(null);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdate = () => {
    if (!selected) {
      showSnack("Seleccione un usuario para continuar", "warning");
      return;
    }
    setEditableFieldsDisabled(false);
  };

  const clearFields = () => {
    setValue("username", "");
    setValue("name", "");
    setValue("password", "");
    setValue("rol_id", "");
    setValue("status", "");
    setDataProject([]);
  };

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    if (newValue) {
      setDataProject(newValue.permittedProjects || []);
      setValue("username", newValue.username);
      setValue("name", newValue.name);
      setValue("rol_id", newValue.rol_id);
      setValue("status", newValue.status);
      setEditableFieldsDisabled(true);
    } else {
      clearFields();
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleOpenDelete = () => setOpenModal(true);
  const handleCloseDelete = () => setOpenModal(false);

  const handleOpenInsertModal = () => {
    if (!selected) {
      showSnack("Seleccione un usuario para continuar", "warning");
      return;
    }
    setOpenInsertModal(true);
  };
  const handleCloseInsertModal = () => setOpenInsertModal(false);

  const handleOpenRemoveModal = (project) => {
    setProjectToRemove(project);
    setOpenRemoveModal(true);
  };
  const handleCloseRemoveModal = () => setOpenRemoveModal(false);

  const handleInsertProject = async () => {
    if (selected && selectedProject) {
      setLoading(true);
      loadApi(`users/add_project/${selected.id}/${selectedProject.id}`, true, "put")
        .then(() => {
          showSnack("Proyecto insertado correctamente", "success");
          setDataProject((prevData) => [
            ...prevData,
            {
              project_name: selectedProject.project_name,
              id: selectedProject.id,
            },
          ]);
          handleCloseInsertModal();
          getData();
        })
        .catch((error) => {
          showSnack(error.message, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      showSnack("Seleccione un proyecto para continuar", "warning");
      return;
    }
  };

  const handleRemoveProject = async () => {
    if (projectToRemove) {
      setLoading(true);
      loadApi(`users/delete_user_project/${projectToRemove.id}`, true, "Delete")
        .then((response) => {
          showSnack(response.data.message, "success");
          setDataProject((prevData) => prevData.filter((proj) => proj.id !== projectToRemove.id));
          handleCloseRemoveModal();
          getData(); // Actualizar los datos para reflejar correctamente la eliminación
        })
        .catch((error) => {
          showSnack(error.message, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };


  const filteredData = useMemo(() => {
    return data.filter(item => item.projects.length > 0).map(item => item.projects[0])
  }, [data, search]);
  const columns = [
    {
      name: "Nombre de proyectos",
      selector: (row) => row.project_name,
      sortable: true,
    },
    {
      name: "Acciones",
      cell: (row) => (
        <Box>
          <IconButton onClick={() => handleOpenRemoveModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <MenuView>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Box
        sx={{
          height: "100%",
          backgroundColor: "#FFFF",
          borderRadius: ".5em",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          overflow: "auto",
        }}
      >
        <Box sx={{ padding: "1em" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            {!creating && (
              <Box>
                <Stack>
                  <Typography sx={{ mb: 1 }}>Buscar usuarios</Typography>
                  <Autocomplete
                    options={data}
                    value={selected}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    noOptionsText={"No hay usuarios disponibles"}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Seleccione una opción..."
                      />
                    )}
                    sx={{ width: '100%' }}
                  />
                </Stack>

              </Box>

            )}

            <Stack>
              {!selected && editableFieldsDisabled && !creating && (
                <Stack sx={{ width: "100%" }}>
                  <Alert severity="warning" sx={{ maxWidth: "100%", justifyContent: "center" }}>
                    Seleccione un usuario primero o cree uno a continuación.
                  </Alert>
                  <Grid container sx={{ justifyContent: "end", mt: 4 }}>
                    <Grid item xs={12} sm={6} md={4} lg={2} sx={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        variant="contained"
                        onClick={handleActivateCreate}
                        sx={{ width: "10.5em" }}
                        type="submit"
                        startIcon={<AddIcon />}
                      >
                        Crear usuario
                      </Button>
                    </Grid>
                  </Grid>

                </Stack>
              )}
              <Grid container spacing={2}>
                {(selected || !editableFieldsDisabled || creating) && (
                  <>
                    <Grid item xs={12} md={12}>
                      <Typography variant="h6" align="center">
                        Crear usuario
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Nombre"
                            disabled={editableFieldsDisabled}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="username"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Correo"
                            disabled={editableFieldsDisabled}
                            error={!!errors.username}
                            helperText={errors.username?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Contraseña"
                            type={showPassword ? "text" : "password"}
                            disabled={editableFieldsDisabled}
                            error={!!errors.password}
                            helperText={errors.password?.message}
                            required
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="rol_id"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            disabled={editableFieldsDisabled}
                            options={[
                              { label: 'Admin', value: 1 },
                              { label: 'Tramitador', value: 2 },
                            ]}
                            getOptionLabel={(option) => option?.label || ""}
                            isOptionEqualToValue={(option, value) => option?.value === value?.value}
                            onChange={(event, newValue) => field.onChange(newValue?.value || "")}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                label="Rol"
                                disabled={editableFieldsDisabled}
                                error={!!errors.rol_id}
                                helperText={errors.rol_id?.message}
                                required
                                sx={textFieldStyle(!editableFieldsDisabled)}
                              />
                            )}
                            value={
                              field.value !== undefined && field.value !== ""
                                ? { label: field.value === 1 ? "Admin" : "Tramitador", value: field.value }
                                : null
                            }
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="status"
                        control={control}
                        defaultValue={""}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            disabled={editableFieldsDisabled}
                            options={[
                              { label: 'Sí', value: "ACTIVO" },
                              { label: 'No', value: "INACTIVO" },
                            ]}
                            getOptionLabel={(option) => option?.label || ""}
                            isOptionEqualToValue={(option, value) => option?.value === value?.value}
                            onChange={(event, newValue) => field.onChange(newValue?.value)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                variant="outlined"
                                label="Estado"
                                disabled={editableFieldsDisabled}
                                error={!!errors.status}
                                helperText={errors.status?.message}
                                required
                                sx={textFieldStyle(!editableFieldsDisabled)}
                              />
                            )}
                            value={
                              field.value !== undefined && field.value !== ""
                                ? { label: field.value === "ACTIVO" ? "Sí" : "No", value: field.value }
                                : null
                            }
                          />
                        )}
                      />
                    </Grid>

                    <Grid container spacing={2} sx={{ justifyContent: "end", mt: 2 }}>
                      {selected && editableFieldsDisabled && (
                        <Grid container spacing={2} sx={{ marginLeft: "1%" }} alignItems="center" >
                          {/* Botón Atrás */}
                          <Grid item xs={12} md={8}>
                            <BackButton setSelected={setSelected} clearFields={clearFields} />
                          </Grid>

                          {/* Botón Eliminar */}
                          <Grid item xs={12} md={2}>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={handleOpenDelete}
                              sx={{ width: "100%" }}
                              startIcon={<DeleteIcon />}
                            >
                              Eliminar
                            </Button>
                          </Grid>

                          {/* Botón Actualizar */}
                          <Grid item xs={12} md={2}>
                            <Button
                              variant="contained"
                              onClick={handleUpdate}
                              sx={{ width: "100%" }}
                              startIcon={<EditIcon />}
                            >
                              Actualizar
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                      {selected && !editableFieldsDisabled && (
                        <Grid container spacing={1} sx={{ marginLeft: "1%" }}>
                          <Grid item xs={12} md={8} lg={10}>
                            <BackButton setEditableFieldsDisabled={setEditableFieldsDisabled} />
                          </Grid>
                          <Grid item xs={12} md={4} lg={2}>
                            <Button
                              variant="contained"
                              onClick={handleSubmit(handleSaveEdit)}
                              sx={{ width: "100%" }}
                              startIcon={<SaveIcon />}
                            >
                              Guardar
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                      {/* {!selected && editableFieldsDisabled && !creating && ( */}

                      {/* )} */}
                      {!selected && !editableFieldsDisabled && (
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setEditableFieldsDisabled(true);
                              setCreating(false);
                            }}
                            sx={{ width: "10em", marginLeft: "35px" }}
                            type="submit"
                          >
                            Atrás
                          </Button>
                          <Button
                            variant="contained"
                            sx={{ width: "10em !!important" }}
                            onClick={handleSubmit(handleCreate)}
                            type="submit"
                            startIcon={<DoneIcon />}
                          >
                            Crear ahora
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>
              {(selected || !editableFieldsDisabled) && !creating && (
                <Fade in={selected || !editableFieldsDisabled} timeout={300}>
                  <Paper elevation={2} sx={{ mt: 3, p: 2, borderRadius: "8px" }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2, flexWrap: 'wrap' }}>
                      <TextField
                        variant="outlined"
                        placeholder="Buscar..."
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          width: { xs: "100%", sm: "auto" },
                          maxWidth: 300,
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderRadius: "8px",
                              transition: "all 0.3s ease",
                            },
                          },
                          mb: { xs: 2, sm: 0 }
                        }}
                      />
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleOpenInsertModal}
                      // sx={{ width: "100%" }}
                      >
                        Insertar a proyecto
                      </Button>
                    </Box>
                    {filteredData.length === 0 ? (
                      <Box p={2} textAlign="center">
                        <Typography variant="body1">No hay datos disponibles para mostrar en la tabla</Typography>
                      </Box>
                    ) : (
                      <Box>
                        <DataTable
                          title="Proyectos de usuario"
                          columns={columns}
                          data={filteredData}
                          pagination
                          dense
                          responsive
                          customStyles={tableStyle}
                        />
                      </Box>
                    )}
                  </Paper>
                </Fade>
              )}
            </Stack>
          </Box>
        </Box>
        <Modal
          open={openModal}
          aria-labelledby="confirmation-modal-title"
          aria-describedby="confirmation-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="confirmation-modal-title" variant="h6" component="h2">
              ¿Estás seguro de eliminar el elemento?
            </Typography>
            <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
              Esta acción no se puede deshacer.
            </Typography>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "row", gap: "1em" }}>
              <Button variant="outlined" color="error" onClick={handleCloseDelete} sx={{ width: "100%" }}>
                Cancelar
              </Button>
              <Button variant="contained" onClick={handleDelete} sx={{ width: "100%" }}>
                Confirmar
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={openInsertModal}
          onClose={handleCloseInsertModal}
          aria-labelledby="insert-modal-title"
          aria-describedby="insert-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="insert-modal-title" variant="h6" component="h2">
              Insertar a Proyecto
            </Typography>
            <Autocomplete
              options={projects}
              getOptionLabel={(option) => option.project_name || ""}
              isOptionEqualToValue={(option, value) => option.name === value.name}
              noOptionsText={"No se encontraron proyectos"}
              onChange={(event, newValue) => setSelectedProject(newValue)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Proyecto" placeholder="Seleccione un proyecto..." />
              )}
              sx={{ mt: 2 }}
            />
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button variant="outlined" color="error" onClick={handleCloseInsertModal} sx={{ mr: 2 }}>
                Cancelar
              </Button>
              <Button variant="contained" color="primary" onClick={handleInsertProject}>
                Insertar
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={openRemoveModal}
          onClose={handleCloseRemoveModal}
          aria-labelledby="remove-modal-title"
          aria-describedby="remove-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="remove-modal-title" variant="h6" component="h2">
              ¿Estás seguro de remover el proyecto?
            </Typography>
            <Typography id="remove-modal-description" sx={{ mt: 2 }}>
              Esta acción no se puede deshacer.
            </Typography>
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Button variant="outlined" color="error" onClick={handleCloseRemoveModal} sx={{ mr: 2 }}>
                Cancelar
              </Button>
              <Button variant="contained" color="primary" onClick={handleRemoveProject}>
                Remover
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </MenuView>
  );
}
