import MenuView from "../../components/MenuView";
import Contenido from "./componentes/contenido"

import {
  Box,

  
} from "@mui/material";



export default function Seguimiento(){

    return(
        <MenuView>

    <Box
        sx={{
          height: "100%",
          backgroundColor: "#FFFF",
          borderRadius: ".5em",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          overflow: "auto",
        }}
      > 
      <Box sx={{ padding: "1em" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            <Contenido/>
            </Box>
        </Box>
      </Box>
         
        </MenuView>
    )
}