import React, { useState } from "react";
import { Modal, Box, Typography, Button, Backdrop, CircularProgress } from "@mui/material";
import useApi from "../../../hooks/useApi";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #FFFF",
  borderRadius: ".5em",
  boxShadow: 24,
  p: 4,
};

const DeletePhaseModal = ({ open, handleClose, currentEdit, showSnack, setData, data }) => {
  const [loading, setLoading] = useState(false);
  const { loadApi } = useApi();

  const handleDelete = () => {
    setLoading(true);
    loadApi(`phase/delete_document_phase/${currentEdit.id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success");
        setData(data.filter((item) => item.id !== currentEdit.id));
        handleClose();
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="delete-phase-modal-title"
        aria-describedby="delete-phase-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="delete-phase-modal-title" variant="h6" component="h2">
            ¿Estás seguro de eliminar el documento?
          </Typography>
          <Typography id="delete-phase-modal-description" sx={{ mt: 2 }}>
            Esta acción no se puede deshacer.
          </Typography>
          <Box sx={{ mt: 2, display: "flex", flexDirection: "row", gap: "1em" }}>
            <Button variant="outlined" color="error" onClick={handleClose} sx={{ width: "100%" }}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              sx={{ width: "100%" }}
              disabled={loading}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
      
      <Backdrop
        open={loading}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default DeletePhaseModal;
