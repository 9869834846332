import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Typography, Stack, IconButton, Box, Grid, Alert } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useApi from "../../../hooks/useApi";
import useSnack from "../../../hooks/useSnack";
import Buscar from "./buscar";
import ListSeguimiento from "./listaSeguimiento";
import ModalComponent from "./modal";
import SnackAlert from "../../../components/SnackAlert";

export default function Contenido() {
  const [search, setSearch] = useState("");
  const [selectedList, setSelectedList] = useState(null);
  const [escritura, setEscritura] = useState([]);
  const [datosTabla, setDatosTabla] = useState([]);
  const [loading, setLoading] = useState(false);
  const [listaOptions, setListaOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const { loadApi } = useApi();
  const { showSnack, msgSB, openSB, typeSB } = useSnack();

  const handleOpenModal = (row) => {
    const escrituraData = escritura.find((item) => item.id === row.writing_id);
    setSelectedRowData(escrituraData);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRowData(null);
  };

  const getLists = async () => {
    setLoading(true);
    loadApi("project/all_projects", true, "get")
      .then(({ data }) => {
        setListaOptions(data || []);
        if (data.length > 0 && !selectedList) {
          setSelectedList(data[0]);
        }
      })
      .catch()
      .finally(() => setLoading(false));
  };

  const getEscrituras = async () => {
    setLoading(true);
    try {
      const response = await loadApi("writing/all_writing", true, "get");
      if (response.data.length > 0) {
        setEscritura(response.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getDatosTabla = async () => {
    setLoading(true);
    try {
      const response = await loadApi("follow_up/all_follow_up", true, "get");
      if (response.data.length > 0) {
        setDatosTabla(response.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLists();
    getEscrituras();
    getDatosTabla();
  }, []);

  const handleListChange = (event, newValue) => {
    setSelectedList(newValue);
  };

  const handleEscriturasUpdate = async () => {
    await getDatosTabla();
    await getEscrituras();
  };

  const tableStyle = {
    header: { style: { backgroundColor: "#FFFF", fontSize: "16px", fontWeight: "bold" } },
    rows: { style: { fontSize: "14px", fontWeight: "400" } },
    columns: { style: { width: "150px", maxWidth: "150px", minWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } },
  };

  const columns = [
    {
      name: "Ver",
      selector: (row) => (
        <IconButton onClick={() => handleOpenModal(row)}>
          <VisibilityIcon />
        </IconButton>
      ),
    },
    { name: "Nom", selector: row => row.nomenclature, sortable: true },
    { name: "Cliente", selector: row => row.third_party_name, sortable: true },
    { name: "Fase", selector: row => row.phases_name, sortable: true },
    { name: "Días", selector: row => row.phases_phaseDays, sortable: true },
    {
      name: "Valor",
      selector: row => new Intl.NumberFormat('es-ES', { style: 'decimal' }).format(row.totalAmount),
      sortable: true
    },
    {
      name: "Valor",
      selector: row => new Intl.NumberFormat('es-ES', { style: 'decimal' }).format(row.paidAmount),
      sortable: true
    }
  ];

  // Modified filteredData to filter by both client name and nomenclature
  const filteredData = useMemo(() => {
    if (!selectedList) return [];
    return datosTabla.filter(
      (item) =>
        item.project_id === selectedList.id && (
          item.third_party_name.toLowerCase().includes(search.trim().toLowerCase()) ||
          item.nomenclature.toLowerCase().includes(search.trim().toLowerCase())
        )
    );
  }, [selectedList, search, datosTabla]);

  return (
    <>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />

      <Grid container spacing={2} sx={{ textAlign: "center", alignItems: "end" }}>
        <Grid item xs={12} sm={6} md={4}>
          <ListSeguimiento selectedList={selectedList} handleListChange={handleListChange} listaOptions={listaOptions} />
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
          <Buscar search={search} setSearch={setSearch} />
        </Grid>
      </Grid>

      {!selectedList ? (
        <Alert severity="warning" sx={{ maxWidth: "100%", justifyContent: "center", mt: 2 }}>
          Seleccione un proyecto.
        </Alert>
      ) : filteredData.length === 0 ? (
        <Box p={2} textAlign="center" sx={{ mt: 2 }}>
          <Typography variant="body1">
            {search
              ? "No se encontraron los datos buscados"
              : "No hay datos disponibles para mostrar en la tabla"}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ pt: 5, pb: 2 }}>
          <DataTable
            title="Valores"
            columns={columns}
            data={filteredData}
            pagination
            dense
            responsive
            customStyles={tableStyle}
          />
        </Box>
      )}

      <ModalComponent
        open={openModal}
        showSnack={showSnack}
        updateList={handleEscriturasUpdate}
        handleClose={handleCloseModal}
        rowData={selectedRowData}
      />
    </>
  );
}