import React, { useEffect, useState } from "react";
import MenuView from "../../components/MenuView";
import {
  Alert,
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  Autocomplete,
  Modal
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DoneIcon from '@mui/icons-material/Done';
import ContactList from "../../components/contactos";
import BackButton from "../../components/buttonBack";


const schema = yup.object().shape({
  bank_code: yup.string().required("Requerido").matches(/^[0-9Ñ+\-*/#]+$/, "Debe contener solo números y símbolos permitidos"),
  bank_name: yup.string().required("Requerido").matches(/^[a-zA-ZÀ-ÿ\s]+$/, "Solo debe contener letras"),
  city: yup.string().required("Requerido").matches(/^[a-zA-ZÀ-ÿ\s]+$/, "Solo debe contener letras"),
  branch: yup.string().required("Requerido").matches(/^[a-zA-ZÀ-ÿ0-9\s#-]+$/, "Dirección invalida"),
  contacts: yup.array().of(
    yup.object().shape({
      contact_name: yup.string().required("Nombre es requerido").matches(/^[a-zA-ZÀ-ÿ\s]+$/, "Nombre debe ser solo letras"),
      phone: yup.string().required("Teléfono es requerido").matches(/^\d+$/, "Teléfono debe ser un número"),
      email: yup.string().required("El correo es requerido").email("Email inválido"),
      position: yup.string().required("El cargo es requerido")
    })
  ),
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #FFFF",
  borderRadius: ".5em",
  boxShadow: 24,
  p: 4,
};

const textFieldStyle = (enabled) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: enabled ? 'black' : 'default',
    },
  },
});


export default function Banks() {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState([]);
  const [editableFieldsDisabled, setEditableFieldsDisabled] = useState(true);
  const [creating, setCreating] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [contacts, setContacts] = useState([]);

  const { control, handleSubmit, setValue, unregister, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const { loadApi } = useApi();
  const { showSnack, msgSB, openSB, typeSB } = useSnack();
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    loadApi("banks/all_banks", true, "get")
      .then((response) => {
        setData(response.data || []);
      })
      .catch((error) => {
        setData([]);
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleActivateCreate = () => {
    setEditableFieldsDisabled(false);
    setCreating(true);
    setSelected(null);
  };

  const handleCreate = async (data) => {
    setLoading(true);
    loadApi("banks", true, "post", {
      ...data,
      contacts: contacts
    })
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
        setCreating(false);
        clearFields();
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveEdit = (data) => {
    setLoading(true);
    loadApi(`banks/update_bank/${selected.id}`, true, "put", {
      ...data,
      contacts: contacts
    })
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    loadApi(`banks/delete_bank/${selected.id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
        setSelected(null)
        clearFields();
        handleCloseDelete();
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdate = () => {
    if (!selected) {
      showSnack("Por favor seleccione un banco antes de continuar", "warning");
      return;
    }
    setEditableFieldsDisabled(false);
  };



  const clearFields = () => {
    setValue("bank_code", "");
    setValue("bank_name", "");
    setValue("city", "");
    setValue("branch", "");
    setContacts([]);
  };

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    if (newValue) {
      setValue("bank_code", newValue.bank_code);
      setValue("bank_name", newValue.bank_name);
      setValue("city", newValue.city);
      setValue("branch", newValue.branch);
      setContacts(newValue.BanksContacts || []);
      setEditableFieldsDisabled(true);
    } else {
      clearFields();
    }
  };

  const handleOpenDelete = () => setOpenModal(true);
  const handleCloseDelete = () => setOpenModal(false);

  return (
    <MenuView>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Box
        sx={{
          height: "100%",
          backgroundColor: "#FFFF",
          borderRadius: ".5em",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          overflow: "auto",
        }}
      >
        <Box sx={{ padding: "1em" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            {!creating && (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Stack sx={{ width: "100%" }}>
                  <Stack sx={{ width: "100%" }}>
                    <Typography sx={{ mb: 1 }}>Buscar bancos</Typography>
                    <Autocomplete
                      options={data}
                      value={selected}
                      getOptionLabel={(option) => option.bank_name || ''}
                      noOptionsText={'No hay bancos disponibles'}
                      onChange={handleChange}
                      isOptionEqualToValue={(option, value) => option.bank_name === value.bank_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          variant="outlined"
                          placeholder="Seleccione un banco..."
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Box>
            )}
            {creating && (
              <Typography variant="h6" component="h2" align="center">
                Crear Banco
              </Typography>
            )}
            <Stack>
              <Grid container spacing={2}>
                {!selected && editableFieldsDisabled && !creating && (
                  <Stack sx={{ width: '100%', pl: 2 }}>
                    <Alert severity="warning" sx={{ maxWidth: '100%', justifyContent: 'center', mt: 2 }}>
                      Seleccione un banco primero o cree uno a continuación.
                    </Alert>
                  </Stack>
                )}
                {(selected || !editableFieldsDisabled || creating) && (
                  <>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="bank_code"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Nit de banco"
                            disabled={editableFieldsDisabled}
                            error={!!errors.bank_code}
                            helperText={errors.bank_code?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="bank_name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Nombre de banco"
                            disabled={editableFieldsDisabled}
                            error={!!errors.bank_name}
                            helperText={errors.bank_name?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="city"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Ciudad"
                            disabled={editableFieldsDisabled}
                            error={!!errors.city}
                            helperText={errors.city?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="branch"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Sucursal"
                            disabled={editableFieldsDisabled}
                            error={!!errors.branch}
                            helperText={errors.branch?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <ContactList
                        contacts={contacts}
                        setContacts={setContacts}
                        control={control}
                        errors={errors}
                        editableFieldsDisabled={editableFieldsDisabled}
                        unregister={unregister}
                        setValue={setValue}
                      />
                    </Grid>

                  </>
                )}
              </Grid>
            </Stack>



            <Stack direction="row" spacing={2} sx={{ justifyContent: "end", mt: 2 }}>
              {selected && editableFieldsDisabled && (
                <Grid container spacing={2} alignItems="center">
                  {/* Botón Atrás */}
                  <Grid item xs={12} md={8}>
                    <BackButton setSelected={setSelected} clearFields={clearFields} />
                  </Grid>

                  {/* Botón Eliminar */}
                  <Grid item xs={12} md={2}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleOpenDelete}
                      sx={{ width: "100%" }}
                      startIcon={<DeleteIcon />}
                    >
                      Eliminar
                    </Button>
                  </Grid>

                  {/* Botón Actualizar */}
                  <Grid item xs={12} md={2}>
                    <Button
                      variant="contained"
                      onClick={handleUpdate}
                      sx={{ width: "100%" }}
                      startIcon={<EditIcon />}
                    >
                      Actualizar
                    </Button>
                  </Grid>
                </Grid>
              )}
              {selected && !editableFieldsDisabled && (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={8} lg={10}>
                      <BackButton setEditableFieldsDisabled={setEditableFieldsDisabled} />
                    </Grid>
                    <Grid item xs={12} md={4} lg={2}>
                      <Button
                        variant="contained"
                        onClick={handleSubmit(handleSaveEdit)}
                        sx={{ width: "100%" }}
                        startIcon={<SaveIcon />}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
              {!selected && editableFieldsDisabled && !creating && (
                <Button
                  variant="contained"
                  onClick={handleActivateCreate}
                  sx={{ width: "auto" }}
                  type="submit"
                  startIcon={<AddIcon />}
                >
                  Crear banco
                </Button>
              )}
              {!selected && !editableFieldsDisabled && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8} lg={10}>
                    <BackButton
                      setEditableFieldsDisabled={setEditableFieldsDisabled}
                      setCreating={setCreating}
                      clearFields={clearFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleCreate)}
                      sx={{ width: "100%" }}
                      type="submit"
                      startIcon={<DoneIcon />}
                    >
                      Crear ahora
                    </Button>

                  </Grid>
                </Grid>
              )}
            </Stack>
          </Box>
        </Box>
        <Modal
          open={openModal}
          aria-labelledby="confirmation-modal-title"
          aria-describedby="confirmation-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="confirmation-modal-title" variant="h6" component="h2">
              ¿Estás seguro de eliminar el elemento?
            </Typography>
            <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
              Esta acción no se puede deshacer.
            </Typography>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "row", gap: "1em" }}>
              <Button variant="outlined" color="error" onClick={handleCloseDelete} sx={{ width: "100%" }}>
                Cancelar
              </Button>
              <Button variant="contained" onClick={handleDelete} sx={{ width: "100%" }}>
                Confirmar
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </MenuView>
  );
}