  // View.js
  import React, { useReducer } from 'react';
  import GlobalHeader from "../../components/header";
  import SideBar from '../../components/SideBar';
  import { Box } from '@mui/material';
  import MenuView from '../../components/MenuView';

  export default function View() {
    return (
      <>
        <MenuView>
          hola
        </MenuView>
      </>
    );
  }
