import { Alert, Snackbar } from "@mui/material";

export default function SnackAlert({ openSB, typeSB, msgSB }) {
  const vertical = "bottom"
  const horizontal = "center"
  return (
    <Snackbar open={openSB} anchorOrigin={{ vertical, horizontal }} sx={{ minWidth: "200px" }}>
      <Alert
        severity={typeSB}
        sx={{
          width: "100%",
          fontSize: ".8rem",
          fontWeight: 600,
        }}
      >
        {msgSB}
      </Alert>
    </Snackbar>
  );
}
