import React, { useState, useMemo, useEffect } from "react";
import { Typography, Stack, Box, Grid } from "@mui/material";
import DataTable from "react-data-table-component";
import useApi from "../../../../hooks/useApi";
import useSnack from "../../../../hooks/useSnack";
import SnackAlert from "../../../../components/SnackAlert";
import Buscar from "../buscar";
import BtnCrear from "./btnCrear";


export function dateToInput(fechaOriginal) {
  if (!!/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/.test(fechaOriginal)) {
    const dateObject = new Date(fechaOriginal);
    const año = dateObject.getUTCFullYear();
    const mes = (dateObject.getUTCMonth() + 1).toString().padStart(2, "0");
    const día = dateObject.getUTCDate().toString().padStart(2, "0");

    return `${año}-${mes}-${día}`;
  } else return fechaOriginal;
}


export default function Historial({ rowData }) {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [historial, setHistorial] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { loadApi } = useApi();
  const { showSnack, msgSB, openSB, typeSB } = useSnack();
  const [openModal, setOpenModal] = useState(false);

  const getHistorial = async () => {
    setLoading(true);
    try {
      const response = await loadApi(`history/all_history/${rowData.id}`, true, "get");
      if (response.data.length > 0) {
        setHistorial(response.data);
      } else {
        setHistorial([])
      }
    } catch (error) {
      showSnack(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getHistorial();
  }, []);

  const tableStyle = {
    header: { style: { backgroundColor: "#FFFF", fontSize: "16px", fontWeight: "bold" } },
    rows: { style: { fontSize: "14px", fontWeight: "400" } },
    columns: { style: { width: "150px", maxWidth: "150px", minWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } },
  };




  const columns = [
    { name: "Fecha", selector: row => dateToInput(row.date), sortable: true },
    { name: "titulo", selector: row => row.title, sortable: true },
    { name: "Descripción", selector: row => row.description, sortable: true },
  ];

  const handleDelete = (row) => {
    setSelectedRowData(row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRowData(null);
  };


  const handleHistorialUpdate = async () => {
    await getHistorial();
  };

  const filteredData = useMemo(() => {
    if (!historial) return [];
    return historial.filter(
      (item) =>
        columns.some((column) =>
          String(column.selector(item)).toLowerCase().includes(search.trim().toLowerCase())
        )
    );
  }, [columns, historial, search]);

  const handleRowClicked = row => {
    setSelectedRowData(row);
  };

  return (
    <>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Stack pt={2} direction="row" justifyContent="space-between" alignItems="center" padding="1em" boxSizing="border-box" gap={2}>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={6} md={8}>
            <Buscar search={search} setSearch={setSearch} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <BtnCrear showSnack={showSnack} handleHistorialUpdate={handleHistorialUpdate} rowData={rowData} />
          </Grid>
        </Grid>
      </Stack>
      {filteredData.length === 0 ? (
        <Box p={2} textAlign="center" sx={{ mt: 2 }}>
          <Typography variant="body1">
            {search ? "No se encontraron los datos buscados" : "No hay datos disponibles para mostrar en la tabla"}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ pt: 5, pb: 2 }}>
          <DataTable
            title="Valores"
            columns={columns}
            data={filteredData}
            pagination
            dense
            responsive
            customStyles={tableStyle}
            onRowClicked={handleRowClicked}
          />
        </Box>
      )}
    </>
  );
}
