import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from "@mui/material";
import useApi from "../../../../hooks/useApi";

export default function ModalCargarDocumento({ open, handleClose, selectedDocument, setSelectedDocument, showSnack, handleUpdateList, tipo, fase }) {
  const { loadApi } = useApi();
  const [loading, setLoading] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedDocument({ ...selectedDocument, file: file });
    setFileSelected(Boolean(file));
  };

  const handleCreate = () => {
    if (typeof tipo === 'boolean') {
      const filteredPhases = fase.projects.map(project =>
        project.phases.filter(phase => phase.orderNumber === fase.current_phase)
      ).flat();

      if (tipo === true) {
        const dataToSend = {
          phases_id: filteredPhases[0].phase_id,
          writing_id: fase.writingId,
          document: selectedDocument.file,
          documents_x_phase_id: selectedDocument.id,
        };
        setLoading(true);
        loadApi(`document`, true, "post", dataToSend, true).then((response) => {
          setTimeout(handleUpdateList, 0);
          setTimeout(handleClose, 0);
          showSnack(response.data.message, "success");
        }).catch(error => showSnack(error.message, "error")).finally(() => setLoading(false));

      } else {
        const dataToSend = {
          phases_id: filteredPhases[0].phase_id,
          writing_id: fase.writingId,
          document_edit: selectedDocument.file,
          documents_x_phase_id: selectedDocument.id,
        };
        setLoading(true);
        loadApi(`document/update_document/${selectedDocument.idLoadedDocument}`, true, "put", dataToSend, true).then((response) => {
          setTimeout(handleUpdateList, 0);
          setTimeout(handleClose, 0);
          setSelectedDocument(null);
          setFileSelected(false);
          showSnack(response.data.message, "success");
        }).catch(error => showSnack(error.message, "error")).finally(() => setLoading(false));

      }
    } else {

      handleCustomLogic();
    }


  };

  const handleCustomLogic = () => {
    const dataToSend = {
      phases_id: selectedDocument.fase,
      writing_id: selectedDocument.writingId,
      document_edit: selectedDocument.file,
      documents_x_phase_id: selectedDocument.id,
    };
    setLoading(true);
    loadApi(`document/update_document/${selectedDocument.idLoadedDocument}`, true, "put", dataToSend, true).then((response) => {
      setTimeout(handleUpdateList, 0);
      setTimeout(handleClose, 0);
      setSelectedDocument(null);
      setFileSelected(false);
      showSnack(response.data.message, "success");
    }).catch(error => showSnack(error.message, "error")).finally(() => setLoading(false));

  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Cargar Documento</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          type="file"
          fullWidth
          onChange={handleFileChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCreate} color="primary" disabled={!fileSelected || loading}>
          {loading ? "Cargando..." : "Cargar"}
        </Button>
        <Button onClick={handleClose} variant="outlined" color="error" disabled={loading}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
