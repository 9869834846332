import { Typography, Stack, Autocomplete, TextField } from "@mui/material";

export default function ListSeguimiento({
  selectedList,
  handleListChange,
  listaOptions,
}) {
  return (
    <>
      <Typography
        variant="body1"
        component="p"
        sx={{ marginBottom: 1, fontSize: 14, fontWeight: "bold" }}
      >
        Nombre del proyecto
      </Typography>
      <Autocomplete
        options={listaOptions}
        getOptionLabel={(option) => option.project_name}
        noOptionsText={"No hay listas disponibles"}
        value={selectedList}
        onChange={handleListChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="Seleccione una opción..."
          />
        )}
        fullWidth
        clearOnEscape
      />
    </>
  );
}
