import React from "react";
import {
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

export default function Buscar({ search, setSearch }) {
  return (
    <TextField
      variant="outlined"
      sx={{ fontSize: 14, fontWeight: "bold" }}
      placeholder="Buscar..."
      value={search}
      onChange={e => setSearch(e.target.value)}
      InputProps={{
        startAdornment: (<InputAdornment position="start"><SearchIcon /></InputAdornment>)
      }}
      fullWidth
    />
  );
}
