import React, { useEffect, useState } from "react";
import MenuView from "../../components/MenuView";
import {
  Alert,
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  Autocomplete,
  Modal
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DoneIcon from '@mui/icons-material/Done';
import ContactList from "../../components/contactos";
import BackButton from "../../components/buttonBack";

// Definición de esquema de validación con yup
const schema = yup.object().shape({
  code_trusts: yup.string().required("Requerido").matches(/^[0-9Ñ+\-*/#]+$/, "Debe contener solo números y símbolos permitidos"),
  name: yup.string().required("Requerido").matches(/^[a-zA-ZÀ-ÿ\s]+$/, "Solo debe contener letras"),
  city: yup.string().required("Requerido").matches(/^[a-zA-ZÀ-ÿ\s]+$/, "Solo debe contener letras"),
  contacts: yup.array().of(
    yup.object().shape({
      contact_name: yup.string().required("Nombre es requerido").matches(/^[a-zA-ZÀ-ÿ\s]+$/, "Nombre debe ser solo letras"),
      phone: yup.string().required("Teléfono es requerido").matches(/^\d+$/, "Teléfono debe ser un número"),
      email: yup.string().required("El correo es requerido").email("Email inválido"),
      position: yup.string().required("El cargo es requerido")
    })
  ),
});


const textFieldStyle = (enabled) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: enabled ? 'black' : 'default',
    },
  },
});

const prefix = "Fiducia";

export default function Trusts() {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState([]);
  const [editableFieldsDisabled, setEditableFieldsDisabled] = useState(true);
  const [creating, setCreating] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const {
    control,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { loadApi } = useApi();
  const { showSnack, msgSB, openSB, typeSB } = useSnack();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    loadApi("trusts/all_trusts", true, "get")
      .then((response) => {
        setData(response.data || []);
      })
      .catch((error) => {
        setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleActivateCreate = () => {
    setEditableFieldsDisabled(false);
    setCreating(true);
    setSelected(null);
  };

  const handleCreate = async (data) => {
    setLoading(true);
    loadApi("trusts/", true, "post", {
      ...data,
      contacts: contacts
    })
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
        setCreating(false);
        clearFields();
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveEdit = (data) => {
    setLoading(true);
    loadApi(`trusts/update_trust/${selected.id}`, true, "put", {
      ...data,
      contacts: contacts
    })
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    loadApi(`trusts/delete_trust/${selected.id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
        clearFields();
        handleCloseDelete();
        setSelected(null);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdate = () => {
    if (!selected) {
      showSnack("Por favor seleccione una fiducia antes de continuar", "warning");
      return;
    }
    setEditableFieldsDisabled(false);
  };

  const clearFields = () => {
    setValue("code_trusts", "");
    setValue("name", "");
    setValue("city", "");
    setContacts([]);
  };

  const handleTrustChange = (event, newValue) => {
    setSelected(newValue);
    if (newValue) {
      setValue("code_trusts", newValue.code_trusts);
      setValue("name", newValue.name);
      setValue("city", newValue.city);
      setContacts(newValue.TrustsContacts || []);
      setEditableFieldsDisabled(true);
    } else {
      clearFields();
    }
  };

  const handleOpenDelete = () => {
    setOpenModal(true);
  }
  const handleCloseDelete = () => {
    setOpenModal(false);
  }
  return (
    <MenuView>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Box
        sx={{
          height: "100%",
          backgroundColor: "#FFFF",
          borderRadius: ".5em",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          overflow: "auto",
        }}
      >
        <Box sx={{ padding: "1em" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            {!creating && (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Stack sx={{ width: "100%" }}>
                  <Stack sx={{ width: "100%" }}>
                    <Typography sx={{ mb: 1 }}>Buscar fiducias</Typography>
                    <Autocomplete
                      options={Array.isArray(data) ? data : []}
                      value={selected}
                      getOptionLabel={(option) => option.name || ''}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      noOptionsText={'No hay fiducias disponibles'}
                      onChange={handleTrustChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          variant="outlined"
                          placeholder="Seleccione una opción..."
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Box>
            )}
            {creating && (
              <Typography variant="h6" component="h2" align="center">
                Crear Fiducia
              </Typography>
            )}
            <Stack>
              <Grid container spacing={2}>
                {!selected && editableFieldsDisabled && !creating && (
                  <Stack sx={{ width: '100%', pl: 2 }}>
                    <Alert severity="warning" sx={{ maxWidth: '100%', justifyContent: 'center', mt: 2 }}>
                      Seleccione una fiducia primero o cree una a continuación.
                    </Alert>
                  </Stack>
                )}
                {(selected || !editableFieldsDisabled || creating) && (
                  <>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="code_trusts"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Nit de fiducia"
                            disabled={editableFieldsDisabled}
                            error={!!errors.code_trusts}
                            helperText={errors.code_trusts?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Nombre de fiducia"
                            disabled={editableFieldsDisabled}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="city"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Ciudad"
                            disabled={editableFieldsDisabled}
                            error={!!errors.city}
                            helperText={errors.city?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <ContactList
                        contacts={contacts}
                        setContacts={setContacts}
                        control={control}
                        errors={errors}
                        editableFieldsDisabled={editableFieldsDisabled}
                        unregister={unregister}
                        setValue={setValue}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Stack>

            <Stack direction="row" spacing={2} sx={{ justifyContent: "end", mt: 2 }}>
              {selected && editableFieldsDisabled && (
                <Grid container spacing={2} alignItems="center">
                  {/* Botón Atrás */}
                  <Grid item xs={12} md={8}>
                    <BackButton setSelected={setSelected} clearFields={clearFields} />
                  </Grid>

                  {/* Botón Eliminar */}
                  <Grid item xs={12} md={2}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleOpenDelete}
                      sx={{ width: "100%" }}
                      startIcon={<DeleteIcon />}
                    >
                      Eliminar
                    </Button>
                  </Grid>

                  {/* Botón Actualizar */}
                  <Grid item xs={12} md={2}>
                    <Button
                      variant="contained"
                      onClick={handleUpdate}
                      sx={{ width: "100%" }}
                      startIcon={<EditIcon />}
                    >
                      Actualizar
                    </Button>
                  </Grid>
                </Grid>
              )}
              {selected && !editableFieldsDisabled && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8} lg={10}>
                    <BackButton setEditableFieldsDisabled={setEditableFieldsDisabled} />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleSaveEdit)}
                      sx={{ width: "100%" }}
                      startIcon={<SaveIcon />}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              )}
              {!selected && editableFieldsDisabled && !creating && (
                <Button
                  variant="contained"
                  onClick={handleActivateCreate}
                  sx={{ width: "auto" }}
                  type="submit"
                  startIcon={<AddIcon />}
                >
                  Crear fiducia
                </Button>
              )}
              {!selected && !editableFieldsDisabled && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8} lg={10}>
                    <BackButton
                      setEditableFieldsDisabled={setEditableFieldsDisabled}
                      setCreating={setCreating}
                      clearFields={clearFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleCreate)}
                      sx={{ width: "100%" }}
                      type="submit"
                      startIcon={<DoneIcon />}
                    >
                      Crear ahora
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Modal
                open={openModal}
                onClose={handleCloseDelete}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                sx={{ alignContent: "center" }}
              >
                <Box sx={{ padding: "2em", backgroundColor: "white", borderRadius: "8px", boxShadow: 24, maxWidth: 400, margin: "auto" }}>
                  <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                    Confirmar eliminación
                  </Typography>
                  <Typography id="modal-description" sx={{ mb: 2 }}>
                    ¿Está seguro de que desea eliminar esta fiducia? Esta acción no se puede deshacer.
                  </Typography>
                  <Stack direction="row" spacing={2} sx={{ justifyContent: "flex-end" }}>
                    <Button onClick={handleCloseDelete} color="error" variant="outlined">Cancelar</Button>
                    <Button onClick={handleDelete} variant="contained" >Confirmar</Button>
                  </Stack>
                </Box>
              </Modal>
            </Stack>
          </Box>
        </Box>
      </Box>
    </MenuView>
  );
}