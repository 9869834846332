import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const BackButton = ({ 
  setSelected, 
  setEditableFieldsDisabled, 
  setCreating,
  clearFields
}) => {

  const handleClick = () => {
    if (setEditableFieldsDisabled) {
      setEditableFieldsDisabled(true);
    }
    if (setCreating) {
      setCreating(false);
    }
    if (setSelected) {
      setSelected(null);
    }
    if (clearFields) {
      clearFields();
    }
  };

  return (
    <Button
      variant="outlined"
      onClick={handleClick}
      sx={{ width: { xs: "100%", md: "250px" } }}
      type="submit"
    >
      Atrás
    </Button>
  );
};

BackButton.propTypes = {
  setSelected: PropTypes.func,
  setEditableFieldsDisabled: PropTypes.func,
  setCreating: PropTypes.func,
  clearFields: PropTypes.func.isRequired,
};

export default BackButton;
