import { Box, Stack, TextField } from '@mui/material';

const Step1 = ({ row }) => {
    return (
        <Stack spacing={2} mt={4}>
            <TextField
                label="Proyectos"
                value={row?.Project?.project_name || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Código constructor"
                value={row?.Constructor?.name || ""}
                variant="outlined"
                readOnly
                fullWidth
            />

            {row?.Constructor && (
                <TextField
                    label="Contacto constructor"
                    value={row?.ConstructorContact?.contact_name || ""}
                    variant="outlined"
                    readOnly
                    fullWidth
                />
            )}
            {row?.ConstructorContact && (
                <Stack direction={{ md: "row", xs: "column" }} spacing={2} sx={{ margin: 0 }}>
                    <Box sx={{ flex: 1 }}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            value={row?.ConstructorContact?.email}
                            readOnly
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <TextField
                            label="Teléfono"
                            variant="outlined"
                            value={row?.ConstructorContact?.phone}
                            readOnly
                            fullWidth
                        />
                    </Box>
                    <Box sx={{ flex: 1 }}>
                        <TextField
                            label="Cargo"
                            variant="outlined"
                            value={row?.ConstructorContact?.position}
                            readOnly
                            fullWidth
                        />
                    </Box>
                </Stack>
            )}

            <TextField
                label="Nomenclatura"
                value={row?.nomenclature || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Prorrata"
                value={row?.prorata || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Matricula"
                value={row?.registrationNumber || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Tower"
                value={row?.tower || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
        </Stack>
    );
};

export default Step1;
