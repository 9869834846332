import React, { useEffect, useState } from "react";
import MenuView from "../../components/MenuView";
import {
  Alert,
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  FormHelperText,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  Autocomplete,
  Modal
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DoneIcon from '@mui/icons-material/Done';
import BackButton from "../../components/buttonBack";

// Definición de esquema de validación con yup
const schema = yup.object().shape({
  identifier_type: yup.string().required("Requerido"),
  identifier: yup.string().required("Requerido").matches(/^\d{7,10}$/, "Debe ser un número entre 7 y 10 dígitos"),
  name: yup.string().required("Requerido").matches(/^[a-zA-ZÀ-ÿ\s]+$/, "Debe ser solo letras y puede contener caracteres especiales"),
  email: yup.string().required("Requerido").email("Email inválido"),
  address: yup.string().required("Requerido").matches(/^[a-zA-ZÀ-ÿ0-9\s#-]+$/, "Dirección invalida"),
  city: yup.string().required("Requerido").matches(/^[a-zA-ZÀ-ÿ\s]+$/, "Debe ser solo letras y puede contener caracteres especiales"),
  phone: yup.string().required("Requerido").matches(/^\d+$/, "Debe ser un número"),
  contact_email: yup.string().email("Email inválido").notRequired(),
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #FFFF",
  borderRadius: ".5em",
  boxShadow: 24,
  p: 4,
};

const textFieldStyle = (enabled) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: enabled ? 'black' : 'default',
    },
  },
});

export default function Clients() {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState([]);
  const [listOptions, setListOptions] = useState([]);
  const [editableFieldsDisabled, setEditableFieldsDisabled] = useState(true);
  const [creating, setCreating] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { loadApi } = useApi();
  const { showSnack, msgSB, openSB, typeSB } = useSnack();

  useEffect(() => {
    getData();
    getLists();
  }, []);

  const getData = async () => {
    setLoading(true);
    loadApi("third_parties/all_third_parties", true, "get")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setData([]);
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLists = () => {
    setLoading(true);
    loadApi("list/all_lists", true, "get")
      .then((response) => {

        const lowerCaseDescription = "Tipo de documento";
        const documentTypeList = response.data.find(
          (item) => item.description.toLowerCase() === lowerCaseDescription.toLowerCase()
        );

        if (documentTypeList.Lists.length > 0) {
          const listsTD = documentTypeList?.Lists;
          const filteredOptions = listsTD.filter((item) => item.status === true);

          setListOptions(
            filteredOptions.map((item) => ({
              value: item.value,
              label: item.meaning
            }))
          );
        } else {
          return []
        }
      })
      .catch((error) => {

      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleActivateCreate = () => {
    setEditableFieldsDisabled(false);
    setCreating(true);
    setSelected(null);
  };

  const handleCreate = async (data) => {
    setLoading(true);
    loadApi("third_parties", true, "post", data)
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
        setCreating(false);
        clearFields();
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveEdit = (data) => {
    setLoading(true);
    loadApi(`third_parties/update_third_parties/${selected.id}`, true, "put", data)
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    loadApi(`third_parties/delete_third_parties/${selected.id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
        clearFields();
        setSelected(null)
        handleCloseDelete();
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdate = () => {
    if (!selected) {
      showSnack("Por favor seleccione un cliente antes de continuar", "warning");
      return;
    }
    setEditableFieldsDisabled(false);
  };

  const clearFields = () => {
    setValue("identifier_type", "");
    setValue("identifier", "");
    setValue("name", "");
    setValue("email", "");
    setValue("address", "");
    setValue("city", "");
    setValue("phone", "");
    setValue("contact_name", "");
    setValue("contact_email", "");
  };

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    if (newValue) {
      setValue("identifier_type", newValue.identifier_type);
      setValue("identifier", newValue.identifier);
      setValue("name", newValue.name);
      setValue("email", newValue.email);
      setValue("address", newValue.address);
      setValue("city", newValue.city);
      setValue("phone", newValue.phone);
      setValue("contact_name", newValue.contact_name || "");
      setValue("contact_email", newValue.contact_email || "");
      setEditableFieldsDisabled(true);
    } else {
      clearFields();
    }
  };

  const handleOpenDelete = () => setOpenModal(true);
  const handleCloseDelete = () => setOpenModal(false);

  return (
    <MenuView>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Box
        sx={{
          height: "100%",
          backgroundColor: "#FFFF",
          borderRadius: ".5em",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
          overflow: "auto",
        }}
      >
        <Box sx={{ padding: "1em" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            {!creating && (
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Stack sx={{ width: "100%" }}>
                  <Stack sx={{ width: "100%" }}>
                    <Typography sx={{ mb: 1 }}>Buscar clientes</Typography>
                    <Autocomplete
                      options={data}
                      value={selected}
                      getOptionLabel={(option) => option.name}
                      noOptionsText={'No hay clientes disponibles'}
                      onChange={handleChange}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          variant="outlined"
                          placeholder="Seleccione un cliente..."
                        />
                      )}
                    />
                  </Stack>
                </Stack>
              </Box>
            )}
            {creating && (
              <Typography variant="h6" component="h2" align="center">
                Crear Cliente
              </Typography>
            )}
            <Stack>
              <Grid container spacing={2}>
                {!selected && editableFieldsDisabled && !creating && (
                  <Stack sx={{ width: '100%', pl: 2 }}>
                    <Alert severity="warning" sx={{ maxWidth: '100%', justifyContent: 'center', mt: 2 }}>
                      Seleccione un cliente primero o cree uno a continuación.
                    </Alert>
                  </Stack>
                )}
                {(selected || !editableFieldsDisabled || creating) && (
                  <>
                    <Grid item xs={12} sm={6} md={4}>
                      <FormControl
                        fullWidth
                        error={!!errors.identifier_type}
                        required
                      >
                        <Controller
                          name="identifier_type"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              options={listOptions}
                              value={listOptions.find(option => option.value === value) || null}
                              isOptionEqualToValue={(option, value) => option.value === value.value}
                              noOptionsText={"No hay tipos de documento disponibles"}
                              getOptionLabel={(option) => option.label || ""}
                              onChange={(event, newValue) => {
                                onChange(newValue ? newValue.value : "");
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Tipo de identificación"
                                  sx={textFieldStyle(!editableFieldsDisabled)}
                                  disabled={editableFieldsDisabled}
                                />
                              )}
                              disabled={editableFieldsDisabled}
                            />
                          )}
                        />
                        <FormHelperText>{errors.identifier_type?.message}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="identifier"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Número de Identificación"
                            sx={textFieldStyle(!editableFieldsDisabled)}
                            disabled={editableFieldsDisabled}
                            error={!!errors.identifier}
                            helperText={errors.identifier?.message}
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Nombre"
                            sx={textFieldStyle(!editableFieldsDisabled)}
                            disabled={editableFieldsDisabled}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Email"
                            sx={textFieldStyle(!editableFieldsDisabled)}
                            disabled={editableFieldsDisabled}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="address"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Dirección"
                            sx={textFieldStyle(!editableFieldsDisabled)}
                            disabled={editableFieldsDisabled}
                            error={!!errors.address}
                            helperText={errors.address?.message}
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="city"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Ciudad"
                            sx={textFieldStyle(!editableFieldsDisabled)}
                            disabled={editableFieldsDisabled}
                            error={!!errors.city}
                            helperText={errors.city?.message}
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="phone"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Teléfono"
                            sx={textFieldStyle(!editableFieldsDisabled)}
                            disabled={editableFieldsDisabled}
                            error={!!errors.phone}
                            helperText={errors.phone?.message}
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="contact_name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Apoderado"
                            sx={textFieldStyle(!editableFieldsDisabled)}
                            disabled={editableFieldsDisabled}
                            error={!!errors.contact_name}
                            helperText={errors.contact_name?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="contact_email"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Email del apoderado"
                            sx={textFieldStyle(!editableFieldsDisabled)}
                            disabled={editableFieldsDisabled}
                            error={!!errors.contact_email}
                            helperText={errors.contact_email?.message}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Stack>

            <Stack direction="row" spacing={2} sx={{ justifyContent: "end", mt: 2 }}>
              {selected && editableFieldsDisabled && (
               <Grid container spacing={2} alignItems="center">
               {/* Botón Atrás */}
               <Grid item xs={12} md={8}>
                 <BackButton setSelected={setSelected} clearFields={clearFields} />
               </Grid>

               {/* Botón Eliminar */}
               <Grid item xs={12} md={2}>
                 <Button
                   variant="outlined"
                   color="error"
                   onClick={handleOpenDelete}
                   sx={{ width: "100%" }}
                   startIcon={<DeleteIcon />}
                 >
                   Eliminar
                 </Button>
               </Grid>

               {/* Botón Actualizar */}
               <Grid item xs={12} md={2}>
                 <Button
                   variant="contained"
                   onClick={handleUpdate}
                   sx={{ width: "100%" }}
                   startIcon={<EditIcon />}
                 >
                   Actualizar
                 </Button>
               </Grid>
             </Grid>
              )}
              {selected && !editableFieldsDisabled && (
                <Grid container spacing={1}>
                <Grid item xs={12} md={8} lg={10}>
                  <BackButton setEditableFieldsDisabled={setEditableFieldsDisabled} />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <Button
                    variant="contained"
                    onClick={handleSubmit(handleSaveEdit)}
                    sx={{ width: "100%" }}
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Grid>
              )}
              {!selected && editableFieldsDisabled && !creating && (
                <Button
                  variant="contained"
                  onClick={handleActivateCreate}
                  sx={{ width: "auto" }}
                  type="submit"
                  startIcon={<AddIcon />}
                >
                  Crear cliente
                </Button>
              )}
              {!selected && !editableFieldsDisabled && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8} lg={10}>
                    <BackButton
                      setEditableFieldsDisabled={setEditableFieldsDisabled}
                      setCreating={setCreating}
                      clearFields={clearFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleCreate)}
                      sx={{ width: "100%" }}
                      type="submit"
                      startIcon={<DoneIcon />}
                    >
                      Crear ahora
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Stack>
          </Box>
        </Box>
        <Modal
          open={openModal}
          aria-labelledby="confirmation-modal-title"
          aria-describedby="confirmation-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="confirmation-modal-title" variant="h6" component="h2">
              ¿Estás seguro de eliminar el elemento?
            </Typography>
            <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>
              Esta acción no se puede deshacer.
            </Typography>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "row", gap: "1em" }}>
              <Button variant="outlined" color="error" onClick={handleCloseDelete} sx={{ width: "100%" }}>
                Cancelar
              </Button>
              <Button variant="contained" onClick={handleDelete} sx={{ width: "100%" }}>
                Confirmar
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </MenuView>
  );
}
