import { Box } from "@mui/material";
import GlobalHeader from "./header";
import SideBar from "./SideBar";
import { useState } from "react";

export default function MenuView({ children }) {
  const [openBar, setOpenBar] = useState(false);

  const handleDrawerToggle = () => {
    setOpenBar(!openBar);
  };

  return (
    <>
      <GlobalHeader />
      <Box sx={{ display: 'flex' }}>
        <SideBar
          open={openBar}
          handleDrawerToggle={handleDrawerToggle}
        />
        <Box component="main" sx={{ flexGrow: 1, p: 3, overflow: 'auto' }}>
          {children}
        </Box>
      </Box>
    </>
  );
}
