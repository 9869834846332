import React from "react";
import { Modal, Fade, Box, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Eliminar from "./Eliminar";

const ModalComponent = ({ open, handleClose, selectedDocument, showSnack,  handleUpdateList }) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: 400,
            bgcolor: "background.paper",
            border: "2px solid #FFFF",
            borderRadius: ".5em",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Eliminar selectedDocument={selectedDocument} handleClose={handleClose}  showSnack={showSnack} handleUpdateList={handleUpdateList} />
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalComponent;
