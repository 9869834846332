import React, { useState, useEffect } from 'react';
import {
    Modal,
    Box,
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Typography,
    Paper,
    Stack,
    Menu,
    MenuItem,
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import { CloudDownload, Update, MoreVert, Delete } from '@mui/icons-material';
import useApi from "../../../hooks/useApi";
import SnackAlert from "../../../components/SnackAlert";
import useSnack from "../../../hooks/useSnack";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const DocumentManager = ({ name, endpoint, endpointGet, endpointPut, endpointDelete }) => {
    const [openModalDocument, setOpenModalDocument] = useState(false);
    const [openModalUpdate, setOpenModalUpdate] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const { showSnack, msgSB, openSB, typeSB } = useSnack();
    const [documentName, setDocumentName] = useState('');
    const [documentFile, setDocumentFile] = useState(null);
    const [documents, setDocuments] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentDocIndex, setCurrentDocIndex] = useState(null);
    const [loading, setLoading] = useState(false);
    const { loadApi } = useApi();
    const [updateDocumentData, setUpdateDocumentData] = useState(null);
    const [documentToDelete, setDocumentToDelete] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setLoading(true);
        loadApi(endpointGet, true, "get")
            .then((response) => {
                setDocuments(response.data || []);
            })
            .catch((error) => {
                setDocuments([]);
                showSnack(error.message, "error");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleClose = () => setOpenModalDocument(false);
    const handleCloseUpdate = () => setOpenModalUpdate(false);
    const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

    const handleAddDocument = () => {
        if (documentName && documentFile) {
            const newDocument = {
                name: documentName,
                document: documentFile,
            };
            setLoading(true);
            loadApi(endpoint, true, "post", newDocument, true)
                .then((response) => {
                    showSnack(response.data.message, "success");
                    setDocuments([...documents, newDocument]);
                })
                .catch((error) => {
                    showSnack(error.message, "error");
                })
                .finally(() => {
                    setLoading(false);
                });
            setDocumentName('');
            setDocumentFile(null);
            handleClose();
        } else {
            showSnack('Por favor, complete el nombre del documento y seleccione un archivo.', 'error');
        }
    };

    const handleUpdateDocument = () => {
        if (updateDocumentData?.name && updateDocumentData?.file) {
            setLoading(true);
            const updatedDocument = {
                ...documents.find(doc => doc.id === updateDocumentData.id),
                name: updateDocumentData.name,
                document_edit: updateDocumentData.file,
            };

            loadApi(`${endpointPut}/${updateDocumentData?.id}`, true, "put", updatedDocument, true)
                .then((response) => {
                    showSnack(response.data.message, "success");

                    const updatedDocuments = documents.map(doc =>
                        doc.id === updateDocumentData.id ? updatedDocument : doc
                    );

                    setDocuments(updatedDocuments);
                })
                .catch((error) => {
                    showSnack(error.message, "error");
                })
                .finally(() => {
                    setLoading(false);
                    handleCloseUpdate();
                });
        } else {
            showSnack('Por favor, complete el nombre del documento y seleccione un archivo.', 'error');
        }
    };

    const handleFileChange = (e) => {
        setDocumentFile(e.target.files[0]);
    };

    const handleUpdateFileChange = (e) => {
        setUpdateDocumentData((prev) => ({ ...prev, file: e.target.files[0] }));
    };

    const handleDownload = (url, fileName) => {
        try {
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
            showSnack('Error al descargar el archivo.', 'error');
        }
    };

    const handleClickMenu = (event, index) => {
        setAnchorEl(event.currentTarget);
        setCurrentDocIndex(index);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setCurrentDocIndex(null);
    };

    const handleDeleteDocument = () => {
        setLoading(true);
        loadApi(`${endpointDelete}/${documentToDelete?.id}`, true, "delete")
            .then((response) => {
                showSnack(response.data.message, "success");
                const updatedDocuments = documents.filter(doc => doc.id !== documentToDelete.id);
                setDocuments(updatedDocuments);
                handleCloseMenu();
            })
            .catch((error) => {
                showSnack(error.message, "error");
            })
            .finally(() => {
                setLoading(false);
                setOpenDeleteDialog(false);
            });
    };

    const handleOpenUpdateModal = (index) => {
        setUpdateDocumentData({
            ...documents[index],
            id: documents[index].id,
        });
        setOpenModalUpdate(true);
        handleCloseMenu();
    };

    const handleOpenDeleteDialog = (index) => {
        setDocumentToDelete(documents[index]);
        setOpenDeleteDialog(true);
        handleCloseMenu();
    };

    const openMenu = Boolean(anchorEl);

    return (
        <Stack mb={5}>
            <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
            <Button variant="contained" onClick={() => setOpenModalDocument(true)}>{name}</Button>

            {/* Add Document Modal */}
            <Modal open={openModalDocument} onClose={handleClose}>
                <Box sx={style}>
                    <Typography variant="h6" component="h2">
                        Agregar Documento
                    </Typography>
                    <TextField
                        fullWidth
                        label="Nombre del Documento"
                        value={documentName}
                        onChange={(e) => setDocumentName(e.target.value)}
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        type="file"
                        onChange={handleFileChange}
                        sx={{ mt: 2 }}
                    />
                    <Button variant="contained" onClick={handleAddDocument} sx={{ mt: 2 }}>Agregar</Button>
                </Box>
            </Modal>

            {/* Update Document Modal */}
            <Modal open={openModalUpdate} onClose={handleCloseUpdate}>
                <Box sx={style}>
                    <Typography variant="h6" component="h2">
                        Actualizar Documento
                    </Typography>
                    <TextField
                        fullWidth
                        label="Nombre del Documento"
                        value={updateDocumentData?.name || ''}
                        onChange={(e) => setUpdateDocumentData((prev) => ({ ...prev, name: e.target.value }))}
                        sx={{ mt: 2 }}
                    />
                    <TextField
                        fullWidth
                        type="file"
                        onChange={handleUpdateFileChange}
                        sx={{ mt: 2 }}
                    />
                    <Button variant="contained" onClick={handleUpdateDocument} sx={{ mt: 2 }}>Actualizar</Button>
                </Box>
            </Modal>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirmar Eliminación"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        ¿Estás seguro de que deseas eliminar este documento? Esta acción no se puede deshacer.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog}>Cancelar</Button>
                    <Button onClick={handleDeleteDocument} color="error" autoFocus>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Documents Table */}
            <TableContainer component={Paper} sx={{ mt: 4 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Acciones</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={2} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            documents.map((doc, index) => (
                                <TableRow key={doc?.id}>
                                    <TableCell>{doc.name}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={(e) => handleClickMenu(e, index)}>
                                            <MoreVert />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={openMenu}
                                            onClose={handleCloseMenu}
                                        >
                                            <MenuItem onClick={() => handleDownload(doc.document.url, doc.document.name)}>
                                                <CloudDownload sx={{ mr: 1 }} /> Descargar
                                            </MenuItem>
                                            <MenuItem onClick={() => handleOpenUpdateModal(index)}>
                                                <Update sx={{ mr: 1 }} /> Actualizar
                                            </MenuItem>
                                            <MenuItem onClick={() => handleOpenDeleteDialog(index)}>
                                                <Delete sx={{ mr: 1 }} /> Eliminar
                                            </MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>
    );
};

export default DocumentManager;
