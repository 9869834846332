import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import MenuView from "../../components/MenuView";
import {
  Alert,
  Box,
  Grid,
  TextField,
  Button,
  FormControl,
  FormHelperText,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  Autocomplete,
  Modal,
  IconButton,
  InputAdornment,
  Paper,
  Fade,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import DoneIcon from '@mui/icons-material/Done';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SnackAlert from "../../components/SnackAlert";
import useApi from "../../hooks/useApi";
import useSnack from "../../hooks/useSnack";
import BackButton from "../../components/buttonBack";
import DeletePhaseModal from './components/modalDelete';


const schema = yup.object().shape({
  phaseName: yup.string().required("Requerido").matches(/^[a-zA-ZÀ-ÿ\s]+$/, "Debe ser solo letras"),
  phaseDays: yup.string().required("Requerido"),
  responsiblePerson: yup.string().required("Requerido"),
});
const options = [
  "Tramitador",
  "Cliente",
  "Banco constructor",
  "Banco financiero",
  "Abogado",
  "Perito",
  "Notaría",
  "Constructor",
  "Fiducia"
];
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 400,
  bgcolor: "background.paper",
  border: "2px solid #FFFF",
  borderRadius: ".5em",
  boxShadow: 24,
  p: 4,
};

const textFieldStyle = (enabled) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: enabled ? 'black' : 'default',
    },
  },
});


const tableStyle = {
  header: { style: { backgroundColor: "#FFFF", fontSize: "16px", fontWeight: "bold" } },
  rows: { style: { fontSize: "14px", fontWeight: "400" } },
  columns: {
    style: {
      width: "150px",
      maxWidth: "150px",
      minWidth: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  },
};

export default function Users() {
  const [loading, setLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [openDeletePhase, setOpenDeletePhase] = useState(false);
  const [selected, setSelected] = useState(null);
  const [data, setData] = useState([]);
  const [dataDocumentsXPhases, setDocumentsXPhases] = useState([]);
  const [search, setSearch] = useState("");
  const [openDeleteDocument, setOpenDeleteDocument] = useState(false)
  const [TN, setTN] = useState([]);
  const [editableFieldsDisabled, setEditableFieldsDisabled] = useState(true);
  const [currentEdit, setCurrentEdit] = useState({
    id: "",
    documentName: "",
    mandatory: true,
    financingTypes: []
  });
  const [newDocument, setNewDocument] = useState({
    documentName: "",
    mandatory: true,
    financingTypes: []
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { loadApi } = useApi();
  const { showSnack, msgSB, openSB, typeSB } = useSnack();

  useEffect(() => {
    getData();
    getTNList();
  }, []);

  const getData = async () => {
    setLoading(true);
    loadApi("phase/all_phases", true, "get")
      .then((response) => {
        setData(response.data || []);
        if (selected) {
          const selectedPhase = response.data.find(phase => phase.id === selected.id);
          setDocumentsXPhases(selectedPhase?.DocumentsXPhases || []);
        }
      })
      .catch((error) => {
        setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleActivateCreate = () => {
    setEditableFieldsDisabled(false);
  };

  const handleCreate = async (data) => {
    setLoading(true);
    loadApi("phase", true, "post", data)
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
        clearFields();
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOpenModalDeleteItem = (row) => {
    setCurrentEdit(row)
    setOpenDeleteDocument(true);
  }
  const handleCloseModalDeleteItem = () => {
    setOpenDeleteDocument(false);
    setCurrentEdit("")
  }


  const handleSaveEdit = async (data) => {
    setLoading(true);
    loadApi(`phase/update_phase/${selected.id}`, true, "put", data)
      .then((response) => {
        showSnack(response.data.message, "success");
        getData();
        setEditableFieldsDisabled(true);
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeletePhase = async () => {
    setLoading(true);
    loadApi(`phase/delete_phase/${selected.id}`, true, "delete")
      .then((response) => {
        showSnack(response.data.message, "success");
        setData(data.filter((item) => item.id !== selected.id));
        setDocumentsXPhases([]);
        setEditableFieldsDisabled(true);
        clearFields();
        setSelected(null);
        handleCloseDeletePhase();
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleMultiSelectChange = (value) => {
    setNewDocument({
      ...newDocument,
      financingTypes: value
    });
  };

  const handleOpenEdit = (row) => {
    setCurrentEdit({
      ...row,
      financingTypes: row.financingTypes ? row.financingTypes.map((type) => ({ meaning: type })) : []
    });
    setOpenEdit(true);
  };


  const handleOpenCreate = () => {
    if (!selected) {
      showSnack("Primero seleccione una fase o cree una antes de insertar documentos requeridos");
    } else {
      setNewDocument({ documentName: "", mandatory: true, financingTypes: [] });
      setOpenCreate(true);
    }
  };

  const handleOpenDeletePhase = () => setOpenDeletePhase(true);
  const handleCloseDeletePhase = () => setOpenDeletePhase(false);

  const handleUpdate = () => {
    if (!selected) {
      showSnack("Por favor seleccione un cliente antes de continuar", "warning");
      return;
    }
    setEditableFieldsDisabled(false);
  };

  const clearFields = () => {
    setValue("phaseName", "");
    setValue("phaseDays", "");
    setValue("responsiblePerson", "");
    setDocumentsXPhases([]);
  };

  const handleChange = (event, newValue) => {
    setSelected(newValue);
    if (newValue) {
      setDocumentsXPhases(newValue.DocumentsXPhases || []);
      setValue("phaseName", newValue.phaseName);
      setValue("phaseDays", newValue.phaseDays);
      setValue("responsiblePerson", newValue.responsiblePerson);
      setEditableFieldsDisabled(true);
    } else {
      clearFields();
    }
  };

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setCurrentEdit((prevEdit) => ({
      ...prevEdit,
      [name]: name === "mandatory" ? value === "1" : value,
    }));
  };

  const handleAutocompleteChange = (event, value) => {
    setCurrentEdit((prevEdit) => ({
      ...prevEdit,
      financingTypes: value,
    }));
  };


  const handleNewDocumentChange = (event) => {
    const { name, value } = event.target;
    setNewDocument((prevDocument) => ({
      ...prevDocument,
      [name]: name === "mandatory" ? value === "1" : value,
    }));
  };

  const handleSaveEditElement = async () => {
    const sendData = {
      documentName: currentEdit.documentName,
      mandatory: currentEdit.mandatory,
      financingTypes: currentEdit.mandatory
        ? currentEdit.financingTypes.map((type) => type.meaning)
        : null
    };

    setLoading(true);
    loadApi(`phase/update_document_phase/${currentEdit.id}`, true, "put", sendData)
      .then((response) => {
        showSnack(response.data.message, "success");
        setDocumentsXPhases((prev) =>
          prev.map((item) => (item.id === currentEdit.id ? sendData : item))
        );
        setOpenEdit(false);
        getData();
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getTNList = async () => {
    setLoading(true);
    loadApi("list/all_lists", true, "get").then(({ data }) => {
      if (data.length > 0) {
        const listTN = data.find(tn => tn.name === "TN")
        if (listTN) {
          setTN(listTN.Lists);
        }
      }
    }).catch(error => showSnack(error.message, "error")).finally(() => setLoading(false));
  };

  const handleSaveNewDocument = async () => {
    const sendData = {
      documentName: newDocument.documentName,
      mandatory: newDocument.mandatory,
      financingTypes: newDocument.mandatory
        ? newDocument.financingTypes.map((type) => type.meaning)
        : null
    };
    setLoading(true);
    loadApi(`phase/create_document_phase/${selected.id}`, true, "post", sendData)
      .then((response) => {
        showSnack(response.data.message, "success");
        setDocumentsXPhases((prev) => [...prev, { ...sendData, id: response.data.newDocument.id }]);
        setOpenCreate(false);
        getData();
      })
      .catch((error) => {
        showSnack(error.message, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filteredData = useMemo(() => {
    return dataDocumentsXPhases.filter((item) => {
      return item.documentName ? item.documentName.toLowerCase().includes(search.toLowerCase()) : false;
    });
  }, [dataDocumentsXPhases, search]);

  const columns = [
    { name: "Nombre de documento", selector: (row) => row.documentName, sortable: true },
    { name: "Obligatorio?", selector: (row) => (row.mandatory ? "Sí" : "No"), sortable: true },
    {
      name: "Tipos de negocio",
      selector: (row) => row.financingTypes ? row.financingTypes.join(', ') : '',
      sortable: true
    },
    {
      name: "Acciones",
      cell: (row) => (
        <>
          <IconButton color="primary" onClick={() => handleOpenEdit(row)}><EditIcon /></IconButton>
          <IconButton color="secondary" onClick={() => handleOpenModalDeleteItem(row)}><DeleteIcon sx={{ color: "red" }} /></IconButton>
        </>
      ),
    },
  ];

  return (
    <MenuView>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}><CircularProgress color="inherit" /></Backdrop>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Box sx={{ height: "100%", backgroundColor: "#FFFF", borderRadius: ".5em", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", overflow: "auto" }}>
        <Box sx={{ padding: "1em" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            {((!selected) || (selected && editableFieldsDisabled)) && (
              <>
                <Typography sx={{ mb: 1 }}>Buscar fases</Typography>
                <Autocomplete
                  options={data}
                  value={selected}
                  getOptionLabel={(option) => option.phaseName}
                  isOptionEqualToValue={(option, value) => option.phaseName === value.phaseName}
                  noOptionsText={'No hay fases disponibles'}
                  onChange={handleChange}
                  renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Seleccione una opción..." />}
                />
              </>
            )}
            {!selected && !editableFieldsDisabled && (
              <Typography sx={{ mb: 1 }}>Crear fases</Typography>
            )}
            {(((selected) && (editableFieldsDisabled)) || (selected && !editableFieldsDisabled)) && (
              <Typography sx={{ mb: 1 }}>Actualizar fases</Typography>
            )}
            <Stack>
              <Grid container spacing={2}>
                {!selected && editableFieldsDisabled && (
                  <Stack sx={{ width: '100%', pl: 2 }}>
                    <Alert severity="warning" sx={{ maxWidth: '100%', justifyContent: 'center', mt: 2 }}>
                      Seleccione una fase primero o cree una a continuación.
                    </Alert>
                  </Stack>
                )}

                {(selected || !editableFieldsDisabled) && (
                  <>

                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="phaseName"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Nombre de fase"
                            disabled={editableFieldsDisabled}
                            error={!!errors.phaseName}
                            helperText={errors.phaseName?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="phaseDays"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            variant="outlined"
                            label="Días"
                            disabled={editableFieldsDisabled}
                            error={!!errors.phaseDays}
                            helperText={errors.phaseDays?.message}
                            required
                            sx={textFieldStyle(!editableFieldsDisabled)}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Controller
                        name="responsiblePerson"
                        control={control}
                        render={({ field }) => (
                          <FormControl fullWidth error={!!errors.responsiblePerson} required>
                            <InputLabel id="responsible-person-label">Persona responsable</InputLabel>
                            <Select
                              {...field}
                              labelId="responsible-person-label"
                              disabled={editableFieldsDisabled}
                              displayEmpty
                            >
                              <MenuItem value="" disabled>
                                Persona responsable
                              </MenuItem>
                              {options.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                  {option.charAt(0).toUpperCase() + option.slice(1)}
                                </MenuItem>
                              ))}
                            </Select>
                            {errors.responsiblePerson && (
                              <FormHelperText>{errors.responsiblePerson.message}</FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>

              {((selected) || (!editableFieldsDisabled && selected)) && (
                <Fade in={true}>
                  <Paper elevation={3} sx={{ mt: 5, boxShadow: "none" }}>
                    <Grid container spacing={2} sx={{ textAlign: "end" }}>
                      <Grid item xs={12} sm={8} md={8} lg={8}>
                        <Stack spacing={2}>
                          <TextField
                            variant="outlined"
                            placeholder="Buscar..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            fullWidth
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={8} md={4} lg={4} >
                        <Button fullWidth variant="contained" color="primary" onClick={handleOpenCreate} startIcon={<AddIcon />}>
                          Crear Documento
                        </Button>
                      </Grid>
                    </Grid>
                    <Box mt={3}>
                      {filteredData.length === 0 ? (
                        <Box p={2} textAlign="center">
                          <Typography variant="body1">Datos no encontrados</Typography>
                        </Box>
                      ) : (
                        <DataTable
                          title="Documentos requeridos"
                          columns={columns}
                          data={filteredData}
                          pagination
                          dense
                          responsive
                          customStyles={tableStyle}
                        />
                      )}
                    </Box>
                  </Paper>
                </Fade>
              )}
            </Stack>
            <Stack direction="row" spacing={2} sx={{ justifyContent: "end", mt: 2 }}>
              {selected && editableFieldsDisabled && (
                <Grid container spacing={2} alignItems="center">
                  {/* Botón Atrás */}
                  <Grid item xs={12} md={8}>
                    <BackButton setSelected={setSelected} clearFields={clearFields} />
                  </Grid>

                  {/* Botón Eliminar */}
                  <Grid item xs={12} md={2}>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleOpenDeletePhase}
                      sx={{ width: "100%" }}
                      startIcon={<DeleteIcon />}
                    >
                      Eliminar
                    </Button>
                  </Grid>

                  {/* Botón Actualizar */}
                  <Grid item xs={12} md={2}>
                    <Button
                      variant="contained"
                      onClick={handleUpdate}
                      sx={{ width: "100%" }}
                      startIcon={<EditIcon />}
                    >
                      Actualizar
                    </Button>
                  </Grid>
                </Grid>
              )}
              {selected && !editableFieldsDisabled && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8} lg={10}>
                    <BackButton setEditableFieldsDisabled={setEditableFieldsDisabled} />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleSaveEdit)}
                      sx={{ width: "100%" }}
                      startIcon={<SaveIcon />}
                    >
                      Guardar
                    </Button>
                  </Grid>
                </Grid>
              )}
              {!selected && editableFieldsDisabled && (
                <Button variant="contained" onClick={handleActivateCreate} sx={{ width: "10em" }} type="submit" startIcon={<AddIcon />}>
                  Crear fase
                </Button>
              )}
              {!selected && !editableFieldsDisabled && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8} lg={10}>
                    <BackButton
                      setEditableFieldsDisabled={setEditableFieldsDisabled}
                      clearFields={clearFields}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={2}>
                    <Button
                      variant="contained"
                      onClick={handleSubmit(handleCreate)}
                      sx={{ width: "100%" }}
                      type="submit"
                      startIcon={<DoneIcon />}
                    >
                      Crear ahora
                    </Button>

                  </Grid>
                </Grid>
              )}
            </Stack>
          </Box>
        </Box>
        <Modal open={openEdit} onClose={() => setOpenEdit(false)}>
          <Box sx={modalStyle}>
            <Typography variant="h6" sx={{ mb: 3 }} component="h2">
              Editar documento
            </Typography>
            <Stack spacing={2}>
              <TextField
                label="Nombre de documento"
                name="documentName"
                value={currentEdit.documentName}
                onChange={handleEditChange}
              />
              <FormControl>
                <InputLabel>Obligatorio</InputLabel>
                <Select
                  label="Obligatorio"
                  name="mandatory"
                  value={currentEdit.mandatory ? "1" : "0"}
                  onChange={handleEditChange}
                >
                  <MenuItem value="1">Sí</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
                <FormHelperText>¿Este documento es obligatorio?</FormHelperText>
              </FormControl>
              {currentEdit.mandatory && (
                <Autocomplete
                  multiple
                  options={TN} // Asegúrate de que TN sea un array de objetos
                  value={currentEdit.financingTypes || []} // Asegúrate de que esto sea un array de objetos
                  getOptionLabel={(option) => option.meaning} // Asegúrate de que 'meaning' sea la propiedad correcta
                  isOptionEqualToValue={(option, value) => option.meaning === value.meaning} // Asegúrate de que la comparación sea correcta
                  onChange={handleAutocompleteChange}
                  renderInput={(params) => (
                    <TextField {...params} label="Opciones relacionadas" placeholder="Selecciona opciones" />
                  )}
                />
              )}
              <Button variant="contained" onClick={handleSaveEditElement} startIcon={<SaveIcon />}>
                Guardar
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Modal open={openCreate} onClose={() => setOpenCreate(false)} aria-labelledby="create-modal-title" aria-describedby="create-modal-description">
          <Box sx={modalStyle}>
            <Typography id="create-modal-title" variant="h6" component="h2">
              Crear nuevo documento
            </Typography>
            <Stack spacing={2}>
              <TextField
                label="Nombre de documento"
                name="documentName"
                value={newDocument.documentName}
                onChange={handleNewDocumentChange}
              />

              <FormControl>
                <InputLabel>Obligatorio</InputLabel>
                <Select
                  label="Obligatorio"
                  name="mandatory"
                  value={newDocument.mandatory ? "1" : "0"}
                  onChange={handleNewDocumentChange}
                >
                  <MenuItem value="1">Sí</MenuItem>
                  <MenuItem value="0">No</MenuItem>
                </Select>
                <FormHelperText>¿Este documento es obligatorio?</FormHelperText>
              </FormControl>

              {/* Campo de selección múltiple, visible solo si es obligatorio */}
              {newDocument.mandatory && (
                <Autocomplete
                  multiple
                  options={TN} // Reemplaza con tu lista de opciones
                  getOptionLabel={(option) => option.meaning}
                  value={newDocument.financingTypes || []}
                  onChange={(event, value) => handleMultiSelectChange(value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Opciones relacionadas" placeholder="Selecciona opciones" />
                  )}
                />
              )}

              <Button variant="contained" onClick={handleSaveNewDocument}>
                Crear
              </Button>
            </Stack>
          </Box>
        </Modal>
        <DeletePhaseModal
          open={openDeleteDocument}
          handleClose={handleCloseModalDeleteItem}
          currentEdit={currentEdit}
          showSnack={showSnack}
          setData={setDocumentsXPhases}
          data={dataDocumentsXPhases}
        />
        <Modal open={openDeletePhase} aria-labelledby="delete-phase-modal-title" aria-describedby="delete-phase-modal-description">
          <Box sx={modalStyle}>
            <Typography id="delete-phase-modal-title" variant="h6" component="h2">
              ¿Estás seguro de eliminar la fase?
            </Typography>
            <Typography id="delete-phase-modal-description" sx={{ mt: 2 }}>
              Esta acción no se puede deshacer.
            </Typography>
            <Box sx={{ mt: 2, display: "flex", flexDirection: "row", gap: "1em" }}>
              <Button variant="outlined" color="error" onClick={handleCloseDeletePhase} sx={{ width: "100%" }}>
                Cancelar
              </Button>
              <Button variant="contained" onClick={handleDeletePhase} sx={{ width: "100%" }}>
                Confirmar
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </MenuView>
  );
}