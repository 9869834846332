import React, { useState} from "react";
import {
  Box,
  Typography,
  Button,
} from "@mui/material";
import useApi from "../../../../hooks/useApi";


export default function Eliminar({ handleClose, selectedDocument, showSnack, handleUpdateList }) {
  const { loadApi } = useApi();
  const [loading, setLoading] = useState(false);

  const handleConfirmDelete = () => {
    setLoading(true);
    loadApi(`document/delete_document/${selectedDocument.idLoadedDocument}`, true, "delete").then((response) => {
      showSnack(response.data.message, "success");
      setTimeout(handleUpdateList, 0);
      setTimeout(handleClose, 0);
    }).catch(error => {
      showSnack(error.message, "error");
    }).finally(() => {
      setLoading(false);
    });
  };

  return(
    <>
      <Typography id="confirmation-modal-title" variant="h6" component="h2">¿Estás seguro de eliminar el elemento?</Typography>
      <Typography id="confirmation-modal-description" sx={{ mt: 2 }}>Esta acción no se puede deshacer.</Typography>
      <Box sx={{ mt: 2, display: "flex", flexDirection: "row", gap: "1em" }}>
        <Button variant="outlined" color="error" sx={{ width: "100%" }} onClick={handleClose} > Cancelar</Button>
        <Button variant="contained" sx={{ width: "100%" }} onClick={handleConfirmDelete}  >Confirmar</Button>
      </Box>
    </>
  )
}
