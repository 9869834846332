import React, { useEffect } from 'react';
import {
  Grid,
  TextField,
  IconButton,
  Stack,
  Button,
  Box,
  Typography,
  Container,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';

const ContactList = ({ contacts, setContacts, control, errors, editableFieldsDisabled, unregister, setValue }) => {

  useEffect(() => {
    if (contacts.length === 0) {
      setContacts([{ contact_name: '', phone: '', email: '', position: '' }]);
    }
  }, [contacts, setContacts]);

  const handleFieldChange = (index, field, value) => {
    setContacts(prevContacts => {
      const updatedContacts = [...prevContacts];
      updatedContacts[index] = { ...updatedContacts[index], [field]: value };
      return updatedContacts;
    });
  };

  const handleAddContact = () => {
    setContacts(prevContacts => [...prevContacts, { contact_name: '', phone: '', email: '', position: '' }]);
  };

  const handleRemoveContact = (index) => {
    if (contacts.length > 1) {
      setContacts(prevContacts => {
        const updatedContacts = prevContacts.filter((_, i) => i !== index);
        unregister(`contacts.${index}.contact_name`);
        unregister(`contacts.${index}.phone`);
        unregister(`contacts.${index}.position`);
        unregister(`contacts.${index}.email`); // Siempre desregistrar email
        updatedContacts.forEach((contact, i) => {
          setValue(`contacts.${i}.contact_name`, contact.contact_name);
          setValue(`contacts.${i}.phone`, contact.phone);
          setValue(`contacts.${i}.position`, contact.position);
          setValue(`contacts.${i}.email`, contact.email);
        });

        return updatedContacts;
      });
    }
  };

  return (
    <>

      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography sx={{ display: { xs: 'none', md: 'block' } }} variant="h6">Contactos</Typography>
        <Box textAlign="center" sx={{ justifyContent: 'center' }}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAddContact}
            disabled={editableFieldsDisabled}
            fullWidth
          >
            Agregar Contacto
          </Button>
        </Box>
      </Stack>
      <Box mt={2}>
        {contacts.map((contact, index) => (
          <Grid container mt={2} spacing={2} key={index}>
            <Grid item xs={12} md={3}>
              <Controller
                name={`contacts.${index}.contact_name`}
                control={control}
                defaultValue={contact.contact_name}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label="Nombre del contacto"
                    value={contact.contact_name}
                    disabled={editableFieldsDisabled}
                    error={!!errors?.contacts?.[index]?.contact_name}
                    helperText={errors?.contacts?.[index]?.contact_name?.message}
                    required
                    onChange={(e) => {
                      field.onChange(e);
                      handleFieldChange(index, 'contact_name', e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Controller
                name={`contacts.${index}.position`}
                control={control}
                defaultValue={contact.position}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label="Cargo"
                    value={contact.position}
                    disabled={editableFieldsDisabled}
                    error={!!errors?.contacts?.[index]?.position}
                    helperText={errors?.contacts?.[index]?.position?.message}
                    required
                    onChange={(e) => {
                      field.onChange(e);
                      handleFieldChange(index, 'position', e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                name={`contacts.${index}.phone`}
                control={control}
                defaultValue={contact.phone}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label="Número del contacto"
                    value={contact.phone}
                    disabled={editableFieldsDisabled}
                    error={!!errors?.contacts?.[index]?.phone}
                    helperText={errors?.contacts?.[index]?.phone?.message}
                    required
                    onChange={(e) => {
                      field.onChange(e);
                      handleFieldChange(index, 'phone', e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                name={`contacts.${index}.email`}
                control={control}
                defaultValue={contact.email}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="outlined"
                    label="Correo electrónico"
                    value={contact.email}
                    disabled={editableFieldsDisabled}
                    error={!!errors?.contacts?.[index]?.email}
                    helperText={errors?.contacts?.[index]?.email?.message}
                    required
                    onChange={(e) => {
                      field.onChange(e);
                      handleFieldChange(index, 'email', e.target.value);
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sx={{ alignContent: 'center' }}>
              <IconButton
                onClick={() => handleRemoveContact(index)}
                disabled={editableFieldsDisabled || contacts.length === 1}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Box>


    </>
  );
};

ContactList.propTypes = {
  contacts: PropTypes.array.isRequired,
  setContacts: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  editableFieldsDisabled: PropTypes.bool,
  unregister: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default ContactList;
