import { Stack, TextField } from '@mui/material';

const Step2 = ({ row }) => {
    return (
        <Stack spacing={2}>
            <TextField
                label="Parqueo 1"
                value={row?.parking1 || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Matricula P1"
                value={row?.registrationParking1 || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Parqueo 2"
                value={row?.parking2 || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Matricula P2"
                value={row?.registrationParking2 || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Util 1"
                value={row?.utility1 || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Matricula U1"
                value={row?.registrationUtility1 || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Util 2"
                value={row?.utility2 || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
            <TextField
                label="Matricula U2"
                value={row?.registrationUtility2 || ""}
                variant="outlined"
                readOnly
                fullWidth
            />
        </Stack>
    );
};

export default Step2;
