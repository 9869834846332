import React, { useState } from 'react';
import { Modal, Fade, Box, IconButton, Tabs, Tab } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Stepper from "./stepper/stepper";
import ModalDocumet from "../../proyectos/components/modalDocumentos"

const ModalComponent = ({ open, handleClose, rowData }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      closeAfterTransition
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: "80%",
            maxWidth: "90vw",
            minHeight: "80vh",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <Tabs value={tabIndex} onChange={handleChange} aria-label="modal tabs">
            <Tab label="Inmueble" />
            <Tab label="Documentos Inmueble" />
          </Tabs>

          {tabIndex === 0 && <Stepper row={rowData} />}
          {tabIndex === 1 && <Box mt={5}><ModalDocumet name={"Agregar documento al inmueble"} endpointGet={`generic_document/${rowData?.id}`} endpoint={`generic_document/${rowData?.id}`} endpointPut={`generic_document/update_document`} endpointDelete={"generic_document/delete_document"} /></Box>}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalComponent;
