import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import useApi from "../../../../hooks/useApi";
import DataTable from "react-data-table-component";
import ModalCargarDocumento from "./modalCargarDocumento";
import ModalEliminar from "./modalEliminar"

export default function Documentos({ rowData, showSnack }) {
  const [data, setData] = useState([]);
  const [fase, setFase] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false)
  const [tipoResponse, setTipoResponse] = useState();
  const { loadApi } = useApi();
  const [loading, setLoading] = useState(false);

  const tableStyle = {
    header: { style: { backgroundColor: "#FFFF", fontSize: "16px", fontWeight: "bold" } },
    rows: { style: { fontSize: "14px", fontWeight: "400" } },
    columns: { style: { width: "150px", maxWidth: "150px", minWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } },
  };

  const handleEdit = (row) => {
    setSelectedDocument(row);
    setOpenUploadModal(true);
    setTipoResponse(false);
  };

  const handleDelete = (row) => {
    setSelectedDocument(row);
    setDeleteModal(true);

  };

  const handleUpload = (row) => {
    setSelectedDocument(row);
    setOpenUploadModal(true);
    setTipoResponse(true);
  };

  const handleUpdateList = () => {
    getFases();
  };

  const handleCloseUploadModal = () => {
    setDeleteModal(false)
    setOpenUploadModal(false);
    setSelectedDocument(null);
  };

  const columns = [
    { name: "Fase", selector: row => row.documentName, sortable: true },
    { name: "Obligatorio?", selector: row => row.mandatory ? "Sí" : "No", sortable: true },
    {
      name: "Acciones",
      selector: (row) => (
        row.isLoaded === false ? (
          <Button
            component="label"
            role={undefined}
            variant="outlined"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            sx={{ border: "none" }}
            onClick={() => handleUpload(row)}
          >
            Subir documento
          </Button>
        ) : (
          <>
            <IconButton onClick={() => handleEdit(row)} color="primary">
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDelete(row)} color="error">
              <DeleteIcon />
            </IconButton>
          </>
        )
      )
    }
  ];

  useEffect(() => {
    if (rowData && rowData.id) {
      getFases();
    }
  }, [rowData]);

  const getFases = async () => {
    setLoading(true);
    try {
      const response = await loadApi(`follow_up/property/${rowData.id}`, true, "get");
      const { current_phase, projects } = response.data;
      setFase(response.data)
      const filteredPhases = projects
        .map(project =>
          project.phases.filter(phase =>
            phase.orderNumber === current_phase || phase.type
          )
        )
        .flat();

      if (filteredPhases.length > 0) {
        setData(filteredPhases[0].documents);

      } else {
        setData([]);
      }
    } catch (error) {
      showSnack(error.message, "error");
    } finally {
      setLoading(false);
    }
  };


  return (
    <>
      <Grid container spacing={2} sx={{ marginBottom: 1, fontSize: 14, fontWeight: "bold", textAlign: "center", alignItems: "center" }}>
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="body1" component="p" sx={{ marginBottom: 1, fontSize: 14, fontWeight: "bold" }}>
            Nomenclatura
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          {rowData?.nomenclature && rowData.nomenclature.length > 0 && (

            <Typography variant="body1" component="p" sx={{ marginBottom: 1, fontSize: 14, fontWeight: "bold" }}>
              {rowData.nomenclature}
            </Typography>
          )}
        </Grid>
      </Grid>
      {data.length === 0 ? (
        <Box p={2} textAlign="center" sx={{ mt: 2 }}>
          <Typography variant="body1">
            {"No hay datos disponibles para mostrar en la tabla"}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ pt: 2, pb: 2 }}>
          <DataTable
            title="Valores"
            columns={columns}
            data={data}
            pagination
            dense
            responsive
            customStyles={tableStyle}
          />
        </Box>
      )}
      <ModalCargarDocumento
        open={openUploadModal}
        handleClose={handleCloseUploadModal}
        selectedDocument={selectedDocument}
        showSnack={showSnack}
        handleUpdateList={handleUpdateList}
        setSelectedDocument={setSelectedDocument}
        tipo={tipoResponse}
        fase={fase}
      />

      <ModalEliminar
        open={deleteModal}
        handleClose={handleCloseUploadModal}
        selectedDocument={selectedDocument}
        handleUpdateList={handleUpdateList}
        showSnack={showSnack}
      />

    </>
  );
}
