import React, { useState, useMemo, useEffect } from "react";
import { Typography, Grid, Box, IconButton } from "@mui/material";
import DataTable from "react-data-table-component";
import useApi from "../../../hooks/useApi";
import useSnack from "../../../hooks/useSnack";
import SnackAlert from "../../../components/SnackAlert";
import Buscar from "../../../components/buscar";
import BtnCrear from "./btnCrear";
import ModalComponent from "./modal"
import DeleteIcon from "@mui/icons-material/Delete";
import ModalEliminar from "./modalEliminar";
import VisibilityIcon from "@mui/icons-material/Visibility";

export default function Contenido() {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [escritura, setEscritura] = useState([]);
  const [openModalVer, setOpenModalVer] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { loadApi } = useApi();
  const { showSnack, msgSB, openSB, typeSB } = useSnack();
  const [openModal, setOpenModal] = useState(false);

  const getEscrituras = async () => {
    setLoading(true);
    try {
      const response = await loadApi("writing/all_writing", true, "get");
      if (response.data.length > 0) {
        setEscritura(response.data);
      } else {
        setEscritura([])
      }
    } catch (error) {
      showSnack(error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEscrituras();
  }, []);

  const tableStyle = {
    header: { style: { backgroundColor: "#FFFF", fontSize: "16px", fontWeight: "bold" } },
    rows: { style: { fontSize: "14px", fontWeight: "400" } },
    columns: { style: { width: "150px", maxWidth: "150px", minWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" } },
  };

  const columns = [
    {
      name: "Ver",
      selector: (row) => (
        <IconButton onClick={() => handleOpenModal(row)} >
          <VisibilityIcon />
        </IconButton>
      ),
    },
    { name: "Nom", selector: row => row.nomenclature, sortable: true },
    { name: "Torre", selector: row => row.tower, sortable: true },
    {
      name: "Valor",
      selector: row => new Intl.NumberFormat('es-ES', { style: 'decimal' }).format(row.totalAmount),
      sortable: true
    },
    {
      name: "Valor",
      selector: row => new Intl.NumberFormat('es-ES', { style: 'decimal' }).format(row.paidAmount),
      sortable: true
    },
    {
      name: "Acciones",
      selector: row => (
        <>
          <IconButton color="secondary" onClick={() => handleDelete(row)}>
            <DeleteIcon sx={{ color: "red" }} />
          </IconButton>
        </>
      )
    }
  ];

  const handleDelete = (row) => {
    setSelectedRowData(row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRowData(null);
  };

  const handleOpenModal = (row) => {
    setSelectedRowData(row);
    setOpenModalVer(true);
  };

  const handleCloseModalVer = () => {
    setOpenModalVer(false);
    setSelectedRowData(null);
  };

  const handleEscriturasUpdate = async () => {
    await getEscrituras();
  };

  // Modified filteredData to only filter by nomenclature
  const filteredData = useMemo(() => {
    if (!escritura) return [];
    return escritura.filter(
      (item) =>
        item.nomenclature.toLowerCase().includes(search.trim().toLowerCase())
    );
  }, [escritura, search]);

  const handleRowClicked = row => {
    setSelectedRowData(row);
  };

  return (
    <>
      <SnackAlert openSB={openSB} typeSB={typeSB} msgSB={msgSB} />
      <Grid container spacing={2} >
        <Grid item xs={12} sm={6} md={8}>
          <Buscar search={search} setSearch={setSearch} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <BtnCrear showSnack={showSnack} handleEscriturasUpdate={handleEscriturasUpdate} />
        </Grid>
      </Grid>
      {filteredData.length === 0 ? (
        <Box p={2} textAlign="center" sx={{ mt: 2 }}>
          <Typography variant="body1">
            {search ? "No se encontraron los datos buscados" : "No hay datos disponibles para mostrar en la tabla"}
          </Typography>
        </Box>
      ) : (
        <Box sx={{ pt: 5, pb: 2 }}>
          <DataTable
            title="Valores"
            columns={columns}
            data={filteredData}
            pagination
            dense
            responsive
            customStyles={tableStyle}
            onRowClicked={handleRowClicked}
          />
        </Box>
      )}
      <ModalComponent open={openModalVer} handleClose={handleCloseModalVer} rowData={selectedRowData} />
      <ModalEliminar
        open={openModal}
        rowData={selectedRowData}
        handleEscriturasUpdate={handleEscriturasUpdate}
        handleClose={handleCloseModal}
        showSnack={showSnack}>
      </ModalEliminar>
    </>
  );
}