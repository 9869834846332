import { useState } from 'react';
import { Stepper, Step, StepLabel, Button, Box, Typography, Stack } from '@mui/material';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';
import Step5 from './steps/Step5';

const steps = ["Detalles de proyecto", "Parqueo y utilidades", "Información de negocio", "Financiación", "Agentes"];

export default function Home({ row }) {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return <Step1 row={row} />;
            case 1:
                return <Step2 row={row} />;
            case 2:
                return <Step3 row={row} />;
            case 3:
                return <Step4 row={row} />;
            case 4:
                return <Step5 row={row} />;
            default:
                return 'Paso desconocido';
        }
    };

    return (
        <Box sx={{ width: '100%' }} mt={10}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Box sx={{ my: 2 }}>
                {activeStep === steps.length ? (
                    <>
                        <Typography variant="h6">¡Has completado todos los pasos!</Typography>
                        <Button onClick={handleReset}>Reiniciar</Button>
                    </>
                ) : (
                    <>
                        {renderStepContent(activeStep)}
                        <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
                            <Button disabled={activeStep === 0} onClick={handleBack}>
                                Atrás
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                disabled={activeStep === steps.length - 1} // Deshabilitar en el último paso
                            >
                                Siguiente
                            </Button>
                        </Stack>
                    </>
                )}
            </Box>
        </Box>
    );
}
