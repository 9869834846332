import React, { useState } from "react";
import { Typography, Tabs, Tab, Grid } from "@mui/material";
import Actualizar from "./contenidoNavtaps/actualizar";
import Compradores from "./contenidoNavtaps/compradores";
import Historial from "./contenidoNavtaps/historial";
import Fases from "./contenidoNavtaps/fases";
import Documentos from "./contenidoNavtaps/documentos";
import DocumentosCargados from "./contenidoNavtaps/documentosCargados";
import DocumentosInmuebles from "./contenidoNavtaps/documentosInmueble";

const Navbtaps = ({ rowData, showSnack, handleClose, updateList }) => {
  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={12}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Detalles del Registro"
            sx={{ marginBottom: 2 }}
          >
            <Tab label="General" />
            <Tab label="Comprador" />
            <Tab label="Fases" />
            <Tab label="Documentos de la fase" />
            <Tab label="Documentos Cargados" />
            <Tab label="Historial" />
            <Tab label="Documentos genéricos" />
          </Tabs>
        </Grid>
      </Grid>
      <Typography
        variant="body1"
        id="modal-description"
        sx={{ top: "0" }}
        gutterBottom
      >
        {tabValue === 0 && (
          <>
            <Actualizar
              rowData={rowData}
              updateList={updateList}
              handleClose={handleClose}
              showSnack={showSnack}
            />
          </>
        )}
        {tabValue === 1 && (
          <>
            <Compradores rowData={rowData} showSnack={showSnack} />
          </>
        )}
        {tabValue === 2 && (
          <>
            <Fases
              rowData={rowData}
              showSnack={showSnack}
              updateList={updateList}
            />
          </>
        )}
        {tabValue === 3 && (
          <>
            <Documentos rowData={rowData} showSnack={showSnack} />
          </>
        )}
        {tabValue === 4 && (
          <>
            <DocumentosCargados rowData={rowData} showSnack={showSnack} />
          </>
        )}
        {tabValue === 5 && (
          <>
            <Historial rowData={rowData} />
          </>
        )}
        {tabValue === 6 && (
          <>
            <DocumentosInmuebles rowData={rowData} showSnack={showSnack} />
          </>
        )}
      </Typography>
    </>
  );
};

export default Navbtaps;
